import CancelIcon from '@mui/icons-material/Cancel';
import { Box, styled, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DatePicker from 'react-datepicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import 'react-phone-input-2/lib/style.css';
import { useMutation, useQuery } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { convertDateToMMDDYYYY, parseDateWithoutTimezone } from '../../../../../helpers/commonFunctions';
import { snackActions } from '../../../../../helpers/SnackUtilsConfigurator';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ReactComponent as Visibility } from '../../../../assets/svg/visibility.svg';
import {
  setClinicianDocuments,
  setProviderSkills,
} from '../../../../../store/slice/form/document_form';
import { getDocumentsDetails, getSkills, uploadPicture } from '../../api';
import { APP_URLs } from '../../../../../constants/appURLs';

interface IProps {
  handleClose: (isSubmit: boolean) => void;
  editData: any;
  type: any;
  show: boolean;
  getIdOnDocumentClick: any;
  reliasData: any;
  docData?: any;
}

const modalsRoot = document.getElementById('root-modals') || document.body;

const AddEditReliasDocs = ({
  show,
  handleClose,
  editData,
  type,
  getIdOnDocumentClick,
  reliasData,
  docData,
}: IProps) => {
  const getProviderDocumentData: any = useSelector(
    (state: any) => state.clinicianDoc.documentFormData?.providerDocuments
  );

  const [documentUrl, setDocumentUrl] = useState('');
  const tomorrow = dayjs().add(1, 'day');

  const dispatch = useDispatch();

  let imgUrl: string = editData?.DocumentUrl
    ? editData?.DocumentUrl
      ? editData?.DocumentUrl
      : ''
    : '';

  const [oshaDocumentFileURL, setOSHADocumentFileURL] = useState('');
  const [oshaDocumentFileName, setOSHADocumentFileName] = useState('');

  const [dementiaDocumentFileURL, setDementiaDocumentFileURL] = useState('');
  const [dementiaDocumentFileName, setDementiaDocumentFileName] = useState('');

  const [abuseDocumentFileURL, setAbuseDocumentFileURL] = useState('');
  const [abuseDocumentFileName, setAbuseDocumentFileName] = useState('');

  const [patientDocumentFileURL, setPatientDocumentFileURL] = useState('');
  const [patientDocumentFileName, setPatientDocumentFileName] = useState('');

  const [hippaDocumentFileURL, setHippaDocumentFileURL] = useState('');
  const [hippaDocumentFileName, setHippaDocumentFileName] = useState('');

  const [oshaExpiryDate, setOshaExpiryDate] = useState<any>('');
  const [dementialExpiryDate, setDementialExpiryDate] = useState<any>('');
  const [abuseExpiryDate, setAbuseExpiryDate] = useState<any>('');
  const [patientExpiryDate, setPatientExpiryDate] = useState<any>('');
  const [hippaExpiryDate, setHippaExpiryDate] = useState<any>('');

  const [FileName, setFileName] = useState<string>(
    imgUrl ? imgUrl?.split('/img/')[1] : ''
  );

  const validationSchema1 = Yup.object().shape({
    oshaDocumentURL: Yup.string().required('This field is required'),
    dementiaDocumentURL: Yup.string().required('This field is required'),
    abuseDocumentURL: Yup.string().required('This field is required'),
    patientDocumentURL: Yup.string().required('This field is required'),
    hippaDocumentURL: Yup.string().required('This field is required'),
    oshaExpiryDate: Yup.date()
      .required('This field is required')
      .min(new Date(), 'Date cannot be in the past'),
    dementiaExpiryDate: Yup.date()
      .required('This field is required')
      .min(new Date(), 'Date cannot be in the past'),
    abuseExpiryDate: Yup.date()
      .required('This field is required')
      .min(new Date(), 'Date cannot be in the past'),
    patientExpiryDate: Yup.date()
      .required('This field is required')
      .min(new Date(), 'Date cannot be in the past'),
    hippaExpiryDate: Yup.date()
      .required('This field is required')
      .min(new Date(), 'Date cannot be in the past'),
  });

  const { mutateAsync: pictureUpload, isLoading: isLoadingDoc } = useMutation(
    'upload-image',
    uploadPicture
  );

  const { data: documentTypes, mutateAsync: getDocumentByParent } = useMutation(
    'documentsDetails',
    getDocumentsDetails
  );

  useEffect(() => {
    getDocumentByParent({
      skip: 0,
      take: 1000,
      hasParent: true,
      parentId: type?.Id,
    });
  }, []);

  const detailsTypeList = documentTypes?.Result;

  const formik: any = useFormik({
    initialValues: {
      oshaExpiryDate: reliasData ? reliasData?.[0]?.expiryDate : '',
      dementiaExpiryDate: reliasData ? reliasData?.[1]?.expiryDate : '',
      abuseExpiryDate: reliasData ? reliasData?.[2]?.expiryDate : '',
      patientExpiryDate: reliasData ? reliasData?.[3]?.expiryDate : '',
      hippaExpiryDate: reliasData ? reliasData?.[4]?.expiryDate : '',
      oshaDocumentURL: reliasData ? reliasData?.[0]?.documentUrl : '',
      dementiaDocumentURL: reliasData ? reliasData?.[1]?.documentUrl : '',
      abuseDocumentURL: reliasData ? reliasData?.[2]?.documentUrl : '',
      patientDocumentURL: reliasData ? reliasData?.[3]?.documentUrl : '',
      hippaDocumentURL: reliasData ? reliasData?.[4]?.documentUrl : '',
    },
    validationSchema: validationSchema1,

    onSubmit: async (values: any) => {
      const getSelectedOSHADate: any = dayjs(values?.oshaExpiryDate);
      const formattedOSHAExpiryDate = convertDateToMMDDYYYY(getSelectedOSHADate?.$d) ?? undefined;

      const getSelectedDementiaDate: any = dayjs(values?.dementiaExpiryDate);
      const formattedDementiaExpiryDate =
      convertDateToMMDDYYYY(getSelectedDementiaDate?.$d) ?? undefined;

      const getSelectedAbuseDate: any = dayjs(values?.abuseExpiryDate);
      const formattedAbuseExpiryDate = convertDateToMMDDYYYY(getSelectedAbuseDate?.$d) ?? undefined;

      const getSelectedPatientDate: any = dayjs(values?.patientExpiryDate);
      const formattedPatientExpiryDate =
      convertDateToMMDDYYYY(getSelectedPatientDate?.$d) ?? undefined;

      const getSelectedHippaDate: any = dayjs(values?.hippaExpiryDate);
      const formattedHippaExpiryDate = convertDateToMMDDYYYY(getSelectedHippaDate?.$d) ?? undefined;

      const defaultDataMap: any = new Map(
        getProviderDocumentData.map((doc: any) => [doc.documentTypeId, doc])
      );

      const filterDocuments = getProviderDocumentData?.filter(
        (e: any) => e?.parentId !== type?.Id
      );

      let currentDocument = getProviderDocumentData?.find(
        (e: any) => e?.parentId === type?.Id
      );

      if (!currentDocument) {
        currentDocument = {
          id: docData?.DocumentType?.Id,
          parentId: docData?.DocumentType?.Id,
        };
      }

      const updateDocs = [
        {
          id: editData?.Id
            ? docData?.ProviderDocuments?.length > 0
              ? currentDocument?.providerDocument[0]?.Id
              : 0
            : detailsTypeList[0]?.Id,
          parentId: type.Id,
          documentTypeId: detailsTypeList[0]?.Id,
          document: {
            documentUrl:
              values.oshaDocumentURL ??
              defaultDataMap.get(values.skillName)?.document?.documentUrl,
            fileName:
              oshaDocumentFileName ??
              defaultDataMap.get(values.skillName)?.document?.fileName,
            expiryDate:
              formattedOSHAExpiryDate &&
              formattedOSHAExpiryDate !== 'Invalid date'
                ? formattedOSHAExpiryDate
                : '',
            providerDocument: getIdOnDocumentClick?.ProviderDocument,
          },
        },
        {
          id: editData?.Id
            ? docData?.ProviderDocuments?.length > 0
              ? currentDocument?.providerDocument[1]?.Id
              : 0
            : detailsTypeList[1]?.Id,
          parentId: type.Id,
          documentTypeId: detailsTypeList[1]?.Id,
          document: {
            documentUrl:
              values.dementiaDocumentURL ??
              defaultDataMap.get(values.skillName)?.document?.documentUrl,
            fileName:
              dementiaDocumentFileName ??
              defaultDataMap.get(values.skillName)?.document?.fileName,
            expiryDate:
              formattedDementiaExpiryDate &&
              formattedDementiaExpiryDate !== 'Invalid date'
                ? formattedDementiaExpiryDate
                : '',
            providerDocument: getIdOnDocumentClick?.ProviderDocument,
          },
        },
        {
          id: editData?.Id
            ? docData?.ProviderDocuments?.length > 0
              ? currentDocument?.providerDocument[2]?.Id
              : 0
            : detailsTypeList[2]?.Id,
          parentId: type.Id,
          documentTypeId: detailsTypeList[2]?.Id,
          document: {
            documentUrl:
              values.abuseDocumentURL ??
              defaultDataMap.get(values.skillName)?.document?.documentUrl,
            fileName:
              abuseDocumentFileName ??
              defaultDataMap.get(values.skillName)?.document?.fileName,
            expiryDate:
              formattedAbuseExpiryDate &&
              formattedAbuseExpiryDate !== 'Invalid date'
                ? formattedAbuseExpiryDate
                : '',
            providerDocument: getIdOnDocumentClick?.ProviderDocument,
          },
        },
        {
          id: editData?.Id
            ? docData?.ProviderDocuments?.length > 0
              ? currentDocument?.providerDocument[3]?.Id
              : 0
            : detailsTypeList[3]?.Id,
          parentId: type.Id,
          documentTypeId: detailsTypeList[3]?.Id,
          document: {
            documentUrl:
              values.patientDocumentURL ??
              defaultDataMap.get(values.skillName)?.document?.documentUrl,
            fileName:
              patientDocumentFileName ??
              defaultDataMap.get(values.skillName)?.document?.fileName,
            expiryDate:
              formattedPatientExpiryDate &&
              formattedPatientExpiryDate !== 'Invalid date'
                ? formattedPatientExpiryDate
                : '',
            providerDocument: getIdOnDocumentClick?.ProviderDocument,
          },
        },
        {
          id: editData?.Id
            ? docData?.ProviderDocuments?.length > 0
              ? currentDocument?.providerDocument[4]?.Id
              : 0
            : detailsTypeList[4]?.Id,
          parentId: type.Id,
          documentTypeId: detailsTypeList[4]?.Id,
          document: {
            documentUrl:
              values.hippaDocumentURL ??
              defaultDataMap.get(values.skillName)?.document?.documentUrl,
            fileName:
              hippaDocumentFileName ??
              defaultDataMap.get(values.skillName)?.document?.fileName,
            expiryDate:
              formattedHippaExpiryDate &&
              formattedHippaExpiryDate !== 'Invalid date'
                ? formattedHippaExpiryDate
                : '',
            providerDocument: getIdOnDocumentClick?.ProviderDocument,
          },
        },
      ];
      const editPayloadType = {
        providerDocuments: [
          ...filterDocuments,
          {
            ...currentDocument,
            providerDocuments: updateDocs,
          },
        ],
      };
      const payloadType = {
        providerDocuments: [
          ...filterDocuments,
          {
            id: 0,
            parentId: type.Id,
            providerDocuments: updateDocs,
          },
        ],
      };
      dispatch(
        setClinicianDocuments(editData?.Id ? editPayloadType : payloadType)
      );
      handleClose(true);
    },
  });

  useEffect(() => {
    if (reliasData || editData) {
      let currentDocument = getProviderDocumentData?.find(
        (e: any) => e?.parentId === type?.Id
      );
      let initialDocumentData = currentDocument?.providerDocument;
      currentDocument = !editData?.Id
        ? getProviderDocumentData?.find(
            (e: any) => e?.providerDocuments?.length > 0
          )
        : currentDocument;

      //Osha
      setOSHADocumentFileName(
        editData?.Id
          ? currentDocument?.providerDocuments?.[0]?.document?.fileName ||
              initialDocumentData?.[0]?.Document?.FileName
          : currentDocument?.providerDocuments?.[0]?.document?.fileName
      );

      formik.setFieldValue(
        'oshaExpiryDate',
        editData?.Id
          ? dayjs(
              currentDocument?.providerDocuments?.[0]?.document?.expiryDate ||
                initialDocumentData?.[0]?.Document?.ExpiryDate ||
                ''
            )
          : currentDocument?.providerDocuments?.[0]?.document?.expiryDate
            ? dayjs(
                currentDocument?.providerDocuments?.[0]?.document?.expiryDate
              )
            : ''
      );
      setOshaExpiryDate(currentDocument?.providerDocuments?.[0]?.document?.expiryDate ||
        initialDocumentData?.[0]?.Document?.ExpiryDate || '')
      formik.setFieldValue(
        'oshaDocumentURL',
        editData?.Id
          ? currentDocument?.providerDocuments?.[0]?.document?.documentUrl ||
              initialDocumentData?.[0]?.Document?.DocumentUrl
          : currentDocument?.providerDocuments?.[0]?.document?.documentUrl
      );
      setOSHADocumentFileURL(
        editData?.Id
          ? currentDocument?.providerDocuments?.[0]?.document?.documentUrl ||
              initialDocumentData?.[0]?.Document?.DocumentUrl
          : currentDocument?.providerDocuments?.[0]?.document?.documentUrl
      );

      //Dement
      setDementiaDocumentFileName(
        editData?.Id
          ? currentDocument?.providerDocuments?.[1]?.document?.fileName ||
              initialDocumentData?.[1]?.Document?.FileName
          : currentDocument?.providerDocuments?.[1]?.document?.fileName
      );
      formik.setFieldValue(
        'dementiaExpiryDate',
        editData?.Id
          ? dayjs(
              currentDocument?.providerDocuments?.[1]?.document?.expiryDate ||
                initialDocumentData?.[1]?.Document?.ExpiryDate ||
                ''
            )
          : currentDocument?.providerDocuments?.[1]?.document?.expiryDate
            ? dayjs(
                currentDocument?.providerDocuments?.[1]?.document?.expiryDate
              )
            : ''
      );
      setDementialExpiryDate(currentDocument?.providerDocuments?.[1]?.document?.expiryDate ||
        initialDocumentData?.[1]?.Document?.ExpiryDate ||
        '')
      formik.setFieldValue(
        'dementiaDocumentURL',
        editData?.Id
          ? currentDocument?.providerDocuments?.[1]?.document?.documentUrl ||
              initialDocumentData?.[1]?.Document?.DocumentUrl
          : currentDocument?.providerDocuments?.[1]?.document?.documentUrl
      );

      setDementiaDocumentFileURL(
        editData?.Id
          ? currentDocument?.providerDocuments?.[1]?.document?.documentUrl ||
              initialDocumentData?.[1]?.Document?.DocumentUrl
          : currentDocument?.providerDocuments?.[1]?.document?.documentUrl
      );

      //Abuse
      setAbuseDocumentFileName(
        editData?.Id
          ? currentDocument?.providerDocuments?.[2]?.document?.fileName ||
              initialDocumentData?.[2]?.Document?.FileName
          : currentDocument?.providerDocuments?.[2]?.document?.fileName
      );
      formik.setFieldValue(
        'abuseExpiryDate',
        editData?.Id
          ? dayjs(
              currentDocument?.providerDocuments?.[2]?.document?.expiryDate ||
                initialDocumentData?.[2]?.Document?.ExpiryDate ||
                ''
            )
          : currentDocument?.providerDocuments?.[2]?.document?.expiryDate
            ? dayjs(
                currentDocument?.providerDocuments?.[2]?.document?.expiryDate
              )
            : ''
      );
      setAbuseExpiryDate(currentDocument?.providerDocuments?.[2]?.document?.expiryDate ||
        initialDocumentData?.[2]?.Document?.ExpiryDate ||
        '')
      formik.setFieldValue(
        'abuseDocumentURL',
        editData?.Id
          ? currentDocument?.providerDocuments?.[2]?.document?.documentUrl ||
              initialDocumentData?.[2]?.Document?.DocumentUrl
          : currentDocument?.providerDocuments?.[2]?.document?.documentUrl
      );
      setAbuseDocumentFileURL(
        editData?.Id
          ? currentDocument?.providerDocuments?.[2]?.document?.documentUrl ||
              initialDocumentData?.[2]?.Document?.DocumentUrl
          : currentDocument?.providerDocuments?.[2]?.document?.documentUrl
      );

      //Patient
      setPatientDocumentFileName(
        editData?.Id
          ? currentDocument?.providerDocuments?.[3]?.document?.fileName ||
              initialDocumentData?.[3]?.Document?.FileName
          : currentDocument?.providerDocuments?.[3]?.document?.fileName
      );
      formik.setFieldValue(
        'patientExpiryDate',
        editData?.Id
          ? dayjs(
              currentDocument?.providerDocuments?.[3]?.document?.expiryDate ||
                initialDocumentData?.[3]?.Document?.ExpiryDate ||
                ''
            )
          : currentDocument?.providerDocuments?.[3]?.document?.expiryDate
            ? dayjs(
                currentDocument?.providerDocuments?.[3]?.document?.expiryDate
              )
            : ''
      );
      setPatientExpiryDate(currentDocument?.providerDocuments?.[3]?.document?.expiryDate ||
        initialDocumentData?.[3]?.Document?.ExpiryDate ||
        '')
      formik.setFieldValue(
        'patientDocumentURL',
        editData?.Id
          ? currentDocument?.providerDocuments?.[3]?.document?.documentUrl ||
              initialDocumentData?.[3]?.Document?.DocumentUrl
          : currentDocument?.providerDocuments?.[3]?.document?.documentUrl
      );
      setPatientDocumentFileURL(
        editData?.Id
          ? currentDocument?.providerDocuments?.[3]?.document?.documentUrl ||
              initialDocumentData?.[3]?.Document?.DocumentUrl
          : currentDocument?.providerDocuments?.[3]?.document?.documentUrl
      );

      //Hippa
      setHippaDocumentFileName(
        editData?.Id
          ? currentDocument?.providerDocuments?.[4]?.document?.fileName ||
              initialDocumentData?.[4]?.Document?.FileName
          : currentDocument?.providerDocuments?.[4]?.document?.fileName
      );
      formik.setFieldValue(
        'hippaExpiryDate',
        editData?.Id
          ? dayjs(
              currentDocument?.providerDocuments?.[4]?.document?.expiryDate ||
                initialDocumentData?.[4]?.Document?.ExpiryDate ||
                ''
            )
          : currentDocument?.providerDocuments?.[4]?.document?.expiryDate
            ? dayjs(
                currentDocument?.providerDocuments?.[4]?.document?.expiryDate
              )
            : ''
      );
      setHippaExpiryDate(currentDocument?.providerDocuments?.[4]?.document?.expiryDate ||
        initialDocumentData?.[4]?.Document?.ExpiryDate ||
        '')
      formik.setFieldValue(
        'hippaDocumentURL',
        editData?.Id
          ? currentDocument?.providerDocuments?.[4]?.document?.documentUrl ||
              initialDocumentData?.[4]?.Document?.DocumentUrl
          : currentDocument?.providerDocuments?.[4]?.document?.documentUrl
      );
      setHippaDocumentFileURL(
        editData?.Id
          ? currentDocument?.providerDocuments?.[4]?.document?.documentUrl ||
              initialDocumentData?.[4]?.Document?.DocumentUrl
          : currentDocument?.providerDocuments?.[4]?.document?.documentUrl
      );
    }
  }, [reliasData, editData?.Id]);

  useEffect(() => {
    if (editData) {
      setDocumentUrl(editData.DocumentUrl);
      formik.setFieldValue('companyName', editData.CompanyId);
    }
  }, [editData]);

  const handleChangeImageUpload = async (e: any, field: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setFileName(file?.name ?? '');
    formData.append('files', file);
    formData.append('fileDriveName', 'CompanyDocuments');
    if (file) {
      let response: any = await pictureUpload(formData);
      if (response?.IsSuccess) {
        snackActions.success(
          response.Message ?? 'Files Uploaded successfully.'
        );

        if (field == 'oshaDocument') {
          formik.setFieldValue('oshaDocumentURL', response?.Result[0]?.FileUrl);
          setOSHADocumentFileURL(response?.Result[0]?.FileUrl);
          setOSHADocumentFileName(response?.Result[0]?.FileName);
        } else if (field == 'dementiaDocument') {
          formik.setFieldValue(
            'dementiaDocumentURL',
            response?.Result[0]?.FileUrl
          );
          setDementiaDocumentFileURL(response?.Result[0]?.FileUrl);
          setDementiaDocumentFileName(response?.Result[0]?.FileName);
        } else if (field == 'abuseDocument') {
          formik.setFieldValue(
            'abuseDocumentURL',
            response?.Result[0]?.FileUrl
          );
          setAbuseDocumentFileURL(response?.Result[0]?.FileUrl);
          setAbuseDocumentFileName(response?.Result[0]?.FileName);
        } else if (field == 'patientDocument') {
          formik.setFieldValue(
            'patientDocumentURL',
            response?.Result[0]?.FileUrl
          );
          setPatientDocumentFileURL(response?.Result[0]?.FileUrl);
          setPatientDocumentFileName(response?.Result[0]?.FileName);
        } else if (field == 'hippaDocument') {
          formik.setFieldValue(
            'hippaDocumentURL',
            response?.Result[0]?.FileUrl
          );
          setHippaDocumentFileURL(response?.Result[0]?.FileUrl);
          setHippaDocumentFileName(response?.Result[0]?.FileName);
        }
        formik.setFieldValue('documentURL', response?.Result[0]?.FileUrl);
      } else {
        snackActions.error(
          response?.message ?? 'Issue in image upload, try again latter!'
        );
      }
    }
  };

  const handleRemoveFile = (field: any) => {
    if (field == 'oshaDocument') {
      formik.setFieldValue('oshaDocumentURL', '');
      setOSHADocumentFileName('');
      setOSHADocumentFileURL('');
    } else if (field == 'dementiaDocument') {
      formik.setFieldValue('dementiaDocumentURL', '');
      setDementiaDocumentFileName('');
      setDementiaDocumentFileURL('');
    } else if (field == 'abuseDocument') {
      formik.setFieldValue('abuseDocumentURL', '');
      setAbuseDocumentFileName('');
      setAbuseDocumentFileURL('');
    } else if (field == 'patientDocument') {
      formik.setFieldValue('patientDocumentURL', '');
      setPatientDocumentFileName('');
      setPatientDocumentFileURL('');
    } else if (field == 'hippaDocument') {
      formik.setFieldValue('hippaDocumentURL', '');
      setHippaDocumentFileName('');
      setAbuseDocumentFileURL('');
    }
  };

  const handleEyeBtnClicked = (type: string) => {
    const baseURL = APP_URLs?.viewDocBaseURL;
    let fileName: string | undefined;
    switch (type) {
      case 'hippaDocument':
        fileName = hippaDocumentFileURL;
        break;
      case 'patientDocumentURL':
        fileName = patientDocumentFileURL;
        break;
      case 'abuseDocumentURL':
        fileName = abuseDocumentFileURL;
        break;
      case 'dementiaDocument':
        fileName = dementiaDocumentFileURL;
        break;
      case 'oshaDocument':
        fileName = oshaDocumentFileURL;
        break;
      default:
        console.error('Unknown document type:', type);
        return;
    }
    if (fileName) {
      window.open(`${baseURL}/${fileName}`, '_blank');
    }
  };

  const handleDateChange = (type: any, value: any) => {
    if(type == "hippaExpiryDate"){
      setHippaExpiryDate(value)
    }
    else if(type == "patientExpiryDate"){
      setPatientExpiryDate(value)
    }
    else if(type == "abuseExpiryDate"){
      setAbuseExpiryDate(value)
    }
    else if(type == "dementiaExpiryDate"){
      setDementialExpiryDate(value)
    }
    else if(type == "oshaExpiryDate"){
      setOshaExpiryDate(value)
    }
    formik.setFieldValue(type, value)
      }

      const currentDate = dayjs(); // Current date
      const minDate = currentDate.add(1, 'day').toDate();

      const StyledDatePicker = styled(DatePicker)`
    border: 1px solid #aa9393;
    border-radius: 5px;
    background-color: #f9f9f9;
    padding: 13px 12px 12px 30px !important;
    width: 100%;
  `;

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <Card className="" style={{ backgroundColor: '#f9f9f9' }}>
        {isLoadingDoc && (
          <Box className="spinner-loader">
            <CircularProgress />
          </Box>
        )}
        <div
          className="modal-header"
          style={{
            backgroundColor: '#f9f9f9',
            borderBottom: '2px solid #919EAB66',
            padding: '15px 20px',
          }}
        >
          <h2 style={{ marginBottom: '0px' }}>Relias/CEUs</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => handleClose(false)}
          >
            <span
              className="material-symbols-outlined"
              style={{ color: '#FE7253' }}
            >
              cancel
            </span>
          </div>
        </div>

        <div
          className="p-6"
          style={{ backgroundColor: '#f9f9f9', borderRadius: '5px' }}
        >
          {detailsTypeList && (
            <form onSubmit={formik.handleSubmit} noValidate className="form">
              <Container fluid>
                <Typography
                  variant="body1"
                  sx={{
                    color: '#103558',
                    fontSize: '14px',
                    fontWeight: 500,
                    marginBottom: '20px',
                  }}
                >
                  Relias offers Continuing Education Units (CEUs) to healthcare
                  professionals, providing ongoing training and certification
                  updates essential for career development and compliance.
                </Typography>
                <Col>
                  <label className="form-label fw-bold">
                    {detailsTypeList[0]?.DocumentType}
                  </label>
                  <Card>
                    <Card.Body
                      style={{
                        padding: '12px',
                      }}
                    >
                      <Row>
                        <Col sm={6}>
                          <label className="form-label fw-normal">
                            Expiry Date{' '}
                            <span className="fw-bolder mt-5 text-danger">
                              *
                            </span>
                          </label>
                          <div className='mb-3 mb-sm-0'>
                            <div className="input-group providerSkill">
                              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  name="oshaExpiryDate"
                                  value={formik?.values?.oshaExpiryDate}
                                  onChange={date =>
                                    formik.setFieldValue('oshaExpiryDate', date)
                                  }
                                  slotProps={{
                                    textField: {
                                      size: 'small',
                                      error: false,
                                    },
                                  }}
                                  views={['year', 'month', 'day']}
                                  minDate={tomorrow}
                                  sx={{
                                    '&.MuiFormControl-root': {
                                      width: '100%',
                                    },
                                    border: '1px solid #919EAB',
                                    borderRadius: '0.475rem !important',
                                    height: '3.4rem !important',
                                    padding: '2px 2px !important',
                                  }}
                              </LocalizationProvider>
                                /> */}
                              <StyledDatePicker
                              showIcon
                              selected={oshaExpiryDate}
                              shouldCloseOnSelect={false}
                              disabledKeyboardNavigation
                              placeholderText="MM/DD/YYYY"
                              minDate={minDate}
                              onChange={(value: any) => handleDateChange('oshaExpiryDate', value)
                              }
                              autoComplete="off"
                            />{' '}
                              {formik.touched.oshaExpiryDate &&
                                formik.errors.oshaExpiryDate && (
                                  <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                      {formik.errors.oshaExpiryDate}
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </Col>
                        <Col sm={6} xs={12}>
                          <div className="d-flex justify-content-between">
                            <label className="form-label fw-normal">
                              Attach Document{' '}
                              <span className="fw-bolder mt-5 text-danger">
                                *
                              </span>
                            </label>
                            {oshaDocumentFileURL &&
                            oshaDocumentFileURL !== '' ? (
                              <span
                                onClick={() =>
                                  handleEyeBtnClicked('oshaDocument')
                                }
                                style={{ cursor: 'pointer' }}
                              >
                                <Visibility />
                              </span>
                            ) : null}
                          </div>
                          <div>
                            {oshaDocumentFileName ? (
                              <div
                                className="d-flex align-items-center"
                                style={fileInputWithDataStyle}
                              >
                                <span>{oshaDocumentFileName}</span>
                                <span
                                  className="material-symbols-outlined"
                                  style={{
                                    color: '#FE7253',
                                    cursor: 'pointer',
                                  }}
                                  onClick={e => {
                                    handleRemoveFile('oshaDocument');
                                  }}
                                >
                                  cancel
                                </span>
                              </div>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  accept=".pdf,.doc,.docx,image/*"
                                  onChange={e => {
                                    const selectedFile =
                                      e.currentTarget.files?.[0];
                                    if (selectedFile) {
                                      formik.setFieldValue(
                                        'file',
                                        selectedFile
                                      );
                                      handleChangeImageUpload(
                                        e,
                                        'oshaDocument'
                                      );
                                    }
                                  }}
                                  className="rounded form-control-file chooseFile"
                                />
                                {formik.touched.oshaDocumentURL &&
                                  formik.errors.oshaDocumentURL && (
                                    <div className="fv-plugins-message-container">
                                      <div className="fv-help-block">
                                        {formik.errors.oshaDocumentURL}
                                      </div>
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                          {formik.touched.file && formik.errors.file && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.file}
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col style={{ marginTop: '10px' }}>
                  <label className="form-label fw-bold">
                    {detailsTypeList[1]?.DocumentType}
                  </label>
                  <Card>
                    <Card.Body
                      style={{
                        padding: '12px',
                      }}
                    >
                      <Row>
                        <Col sm={6} xs={12}>
                          <label className="form-label fw-normal">
                            Expiry Date{' '}
                            <span className="fw-bolder mt-5 text-danger">
                              *
                            </span>
                          </label>
                          <div className="mb-3 mb-sm-0">
                            <div className="input-group providerSkill">
                              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  name="dementiaExpiryDate"
                                  value={formik?.values?.dementiaExpiryDate}
                                  onChange={date =>
                                    formik.setFieldValue(
                                      'dementiaExpiryDate',
                                      date
                                    )
                                  }
                                  slotProps={{
                                    textField: {
                                      size: 'small',
                                      error: false,
                                    },
                                  }}
                                  views={['year', 'month', 'day']}
                                  minDate={tomorrow}
                                  sx={{
                                    '&.MuiFormControl-root': {
                                      width: '100%',
                                    },
                                    border: '1px solid #919EAB',
                                    borderRadius: '0.475rem !important',
                                    height: '3.4rem !important',
                                    padding: '2px 2px !important',
                                  }}
                                />
                              </LocalizationProvider> */}
                              <StyledDatePicker
                              showIcon
                              selected={dementialExpiryDate}
                              shouldCloseOnSelect={false}
                              disabledKeyboardNavigation
                              placeholderText="MM/DD/YYYY"
                              minDate={minDate}
                              onChange={(value: any) => handleDateChange('dementiaExpiryDate', value)
                              }
                              autoComplete="off"
                            />{' '}
                              {formik.touched.dementiaExpiryDate &&
                                formik.errors.dementiaExpiryDate && (
                                  <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                      {formik.errors.dementiaExpiryDate}
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </Col>
                        <Col sm={6} xs={12}>
                          <div className="d-flex justify-content-between">
                            <label className="form-label fw-normal">
                              Attach Document{' '}
                              <span className="fw-bolder mt-5 text-danger">
                                *
                              </span>
                            </label>
                            {dementiaDocumentFileName &&
                            dementiaDocumentFileName !== '' ? (
                              <span
                                onClick={() =>
                                  handleEyeBtnClicked('dementiaDocument')
                                }
                                style={{ cursor: 'pointer' }}
                              >
                                <Visibility />
                              </span>
                            ) : null}
                          </div>
                          <div>
                            {dementiaDocumentFileName ? (
                              <div
                                className="d-flex align-items-center"
                                style={fileInputWithDataStyle}
                              >
                                <span>{dementiaDocumentFileName}</span>
                                <span
                                  className="material-symbols-outlined"
                                  style={{
                                    color: '#FE7253',
                                    cursor: 'pointer',
                                  }}
                                  onClick={e => {
                                    handleRemoveFile('dementiaDocument');
                                  }}
                                >
                                  cancel
                                </span>
                              </div>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  accept=".pdf,.doc,.docx,image/*"
                                  onChange={e => {
                                    const selectedFile =
                                      e.currentTarget.files?.[0];
                                    if (selectedFile) {
                                      formik.setFieldValue(
                                        'file',
                                        selectedFile
                                      );
                                      handleChangeImageUpload(
                                        e,
                                        'dementiaDocument'
                                      );
                                    }
                                  }}
                                  className="rounded form-control-file chooseFile"
                                />
                                {formik.touched.dementiaDocumentURL &&
                                  formik.errors.dementiaDocumentURL && (
                                    <div className="fv-plugins-message-container">
                                      <div className="fv-help-block">
                                        {formik.errors.dementiaDocumentURL}
                                      </div>
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                          {formik.touched.file && formik.errors.file && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.file}
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col style={{ marginTop: '10px' }}>
                  <label className="form-label fw-bold">
                    {detailsTypeList[2]?.DocumentType}
                  </label>
                  <Card>
                    <Card.Body
                      style={{
                        padding: '12px',
                      }}
                    >
                      <Row>
                        <Col sm={6} xs={12}>
                          <label className="form-label fw-normal">
                            Expiry Date{' '}
                            <span className="fw-bolder mt-5 text-danger">
                              *
                            </span>
                          </label>
                          <div className="mb-3 mb-sm-0">
                            <div className="input-group providerSkill">
                              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  name="abuseExpiryDate"
                                  value={formik?.values?.abuseExpiryDate}
                                  onChange={date =>
                                    formik.setFieldValue(
                                      'abuseExpiryDate',
                                      date
                                    )
                                  }
                                  slotProps={{
                                    textField: {
                                      size: 'small',
                                      error: false,
                                    },
                                  }}
                                  views={['year', 'month', 'day']}
                                  minDate={tomorrow}
                                  sx={{
                                    '&.MuiFormControl-root': {
                                      width: '100%',
                                    },
                                    border: '1px solid #919EAB',
                                    borderRadius: '0.475rem !important',
                                    height: '3.4rem !important',
                                    padding: '2px 2px !important',
                                  }}
                                />
                              </LocalizationProvider> */}
                              <StyledDatePicker
                              showIcon
                              selected={abuseExpiryDate}
                              shouldCloseOnSelect={false}
                              disabledKeyboardNavigation
                              placeholderText="MM/DD/YYYY"
                              minDate={minDate}
                              onChange={(value: any) => handleDateChange('abuseExpiryDate', value)
                              }
                              autoComplete="off"
                            />{' '}
                              {formik.touched.abuseExpiryDate &&
                                formik.errors.abuseExpiryDate && (
                                  <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                      {formik.errors.abuseExpiryDate}
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </Col>
                        <Col sm={6} xs={12}>
                          <div className="d-flex justify-content-between">
                            <label className="form-label fw-normal">
                              Attach Document{' '}
                              <span className="fw-bolder mt-5 text-danger">
                                *
                              </span>
                            </label>
                            {abuseDocumentFileName &&
                            abuseDocumentFileName !== '' ? (
                              <span
                                onClick={() =>
                                  handleEyeBtnClicked('abuseDocumentURL')
                                }
                                style={{ cursor: 'pointer' }}
                              >
                                <Visibility />
                              </span>
                            ) : null}
                          </div>
                          <div>
                            {abuseDocumentFileName ? (
                              <div
                                className="d-flex align-items-center"
                                style={fileInputWithDataStyle}
                              >
                                <span>{abuseDocumentFileName}</span>
                                <span
                                  className="material-symbols-outlined"
                                  style={{
                                    color: '#FE7253',
                                    cursor: 'pointer',
                                  }}
                                  onClick={e => {
                                    handleRemoveFile('abuseDocument');
                                  }}
                                >
                                  cancel
                                </span>
                              </div>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  accept=".pdf,.doc,.docx,image/*"
                                  onChange={e => {
                                    const selectedFile =
                                      e.currentTarget.files?.[0];
                                    if (selectedFile) {
                                      formik.setFieldValue(
                                        'file',
                                        selectedFile
                                      );
                                      handleChangeImageUpload(
                                        e,
                                        'abuseDocument'
                                      );
                                    }
                                  }}
                                  className="rounded form-control-file chooseFile"
                                />
                                {formik.touched.abuseDocumentURL &&
                                  formik.errors.abuseDocumentURL && (
                                    <div className="fv-plugins-message-container">
                                      <div className="fv-help-block">
                                        {formik.errors.abuseDocumentURL}
                                      </div>
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                          {formik.touched.file && formik.errors.file && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.file}
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col style={{ marginTop: '10px' }}>
                  <label className="form-label fw-bold">
                    {detailsTypeList[3]?.DocumentType}
                  </label>
                  <Card>
                    <Card.Body
                      style={{
                        padding: '12px',
                      }}
                    >
                      <Row>
                        <Col sm={6} xs={12}>
                          <label className="form-label fw-normal">
                            Expiry Date{' '}
                            <span className="fw-bolder mt-5 text-danger">
                              *
                            </span>
                          </label>
                          <div className="mb-3 mb-sm-0">
                            <div className="input-group providerSkill">
                              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  name="patientExpiryDate"
                                  value={formik?.values?.patientExpiryDate}
                                  onChange={date =>
                                    formik.setFieldValue(
                                      'patientExpiryDate',
                                      date
                                    )
                                  }
                                  slotProps={{
                                    textField: {
                                      size: 'small',
                                      error: false,
                                    },
                                  }}
                                  views={['year', 'month', 'day']}
                                  minDate={tomorrow}
                                  sx={{
                                    '&.MuiFormControl-root': {
                                      width: '100%',
                                    },
                                    border: '1px solid #919EAB',
                                    borderRadius: '0.475rem !important',
                                    height: '3.4rem !important',
                                    padding: '2px 2px !important',
                                  }}
                                />
                              </LocalizationProvider> */}
                              <StyledDatePicker
                              showIcon
                              selected={patientExpiryDate}
                              shouldCloseOnSelect={false}
                              disabledKeyboardNavigation
                              placeholderText="MM/DD/YYYY"
                              minDate={minDate}
                              onChange={(value: any) => handleDateChange('patientExpiryDate', value)
                              }
                              autoComplete="off"
                            />{' '}
                              {formik.touched.patientExpiryDate &&
                                formik.errors.patientExpiryDate && (
                                  <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                      {formik.errors.patientExpiryDate}
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </Col>
                        <Col sm={6} xs={12}>
                          <div className="d-flex justify-content-between">
                            <label className="form-label fw-normal">
                              Attach Document{' '}
                              <span className="fw-bolder mt-5 text-danger">
                                *
                              </span>
                            </label>
                            {patientDocumentFileName &&
                            patientDocumentFileName !== '' ? (
                              <span
                                onClick={() =>
                                  handleEyeBtnClicked('patientDocumentURL')
                                }
                                style={{ cursor: 'pointer' }}
                              >
                                <Visibility />
                              </span>
                            ) : null}
                          </div>
                          <div>
                            {patientDocumentFileName ? (
                              <div
                                className="d-flex align-items-center"
                                style={fileInputWithDataStyle}
                              >
                                <span>{patientDocumentFileName}</span>
                                <span
                                  className="material-symbols-outlined"
                                  style={{
                                    color: '#FE7253',
                                    cursor: 'pointer',
                                  }}
                                  onClick={e => {
                                    handleRemoveFile('patientDocument');
                                  }}
                                >
                                  cancel
                                </span>
                              </div>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  accept=".pdf,.doc,.docx,image/*"
                                  onChange={e => {
                                    const selectedFile =
                                      e.currentTarget.files?.[0];
                                    if (selectedFile) {
                                      formik.setFieldValue(
                                        'file',
                                        selectedFile
                                      );
                                      handleChangeImageUpload(
                                        e,
                                        'patientDocument'
                                      );
                                    }
                                  }}
                                  className="rounded form-control-file chooseFile"
                                />
                                {formik.touched.patientDocumentURL &&
                                  formik.errors.patientDocumentURL && (
                                    <div className="fv-plugins-message-container">
                                      <div className="fv-help-block">
                                        {formik.errors.patientDocumentURL}
                                      </div>
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                          {formik.touched.file && formik.errors.file && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.file}
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col style={{ marginTop: '10px' }}>
                  <label className="form-label fw-bold">
                    {detailsTypeList[4]?.DocumentType}
                  </label>
                  <Card>
                    <Card.Body
                      style={{
                        padding: '12px',
                      }}
                    >
                      <Row>
                        <Col sm={6} xs={12}>
                          <label className="form-label fw-normal">
                            Expiry Date{' '}
                            <span className="fw-bolder mt-5 text-danger">
                              *
                            </span>
                          </label>
                          <div className="mb-3 mb-sm-0">
                            <div className="input-group providerSkill">
                              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                  name="hippaExpiryDate"
                                  value={formik?.values?.hippaExpiryDate}
                                  onChange={date =>
                                    formik.setFieldValue(
                                      'hippaExpiryDate',
                                      date
                                    )
                                  }
                                  slotProps={{
                                    textField: {
                                      size: 'small',
                                      error: false,
                                    },
                                  }}
                                  views={['year', 'month', 'day']}
                                  minDate={tomorrow}
                                  sx={{
                                    '&.MuiFormControl-root': {
                                      width: '100%',
                                    },
                                    border: '1px solid #919EAB',
                                    borderRadius: '0.475rem !important',
                                    height: '3.4rem !important',
                                    padding: '2px 2px !important',
                                  }}
                                />
                              </LocalizationProvider> */}
                              <StyledDatePicker
                              showIcon
                              selected={hippaExpiryDate}
                              shouldCloseOnSelect={false}
                              disabledKeyboardNavigation
                              placeholderText="MM/DD/YYYY"
                              minDate={minDate}
                              onChange={(value: any) => handleDateChange('hippaExpiryDate', value)
                              }
                              autoComplete="off"
                            />{' '}
                              {formik.touched.hippaExpiryDate &&
                                formik.errors.hippaExpiryDate && (
                                  <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                      {formik.errors.hippaExpiryDate}
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </Col>
                        <Col sm={6} xs={12}>
                          <div className="d-flex justify-content-between">
                            <label className="form-label fw-normal">
                              Attach Document{' '}
                              <span className="fw-bolder mt-5 text-danger">
                                *
                              </span>
                            </label>
                            {hippaDocumentFileName &&
                            hippaDocumentFileName !== '' ? (
                              <span
                                onClick={() =>
                                  handleEyeBtnClicked('hippaDocument')
                                }
                                style={{ cursor: 'pointer' }}
                              >
                                <Visibility />
                              </span>
                            ) : null}
                          </div>
                          <div>
                            {hippaDocumentFileName ? (
                              <div
                                className="d-flex align-items-center"
                                style={fileInputWithDataStyle}
                              >
                                <span>{hippaDocumentFileName}</span>
                                <span
                                  className="material-symbols-outlined"
                                  style={{
                                    color: '#FE7253',
                                    cursor: 'pointer',
                                  }}
                                  onClick={e => {
                                    handleRemoveFile('hippaDocument');
                                  }}
                                >
                                  cancel
                                </span>
                              </div>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  accept=".pdf,.doc,.docx,image/*"
                                  onChange={e => {
                                    const selectedFile =
                                      e.currentTarget.files?.[0];
                                    if (selectedFile) {
                                      formik.setFieldValue(
                                        'file',
                                        selectedFile
                                      );
                                      handleChangeImageUpload(
                                        e,
                                        'hippaDocument'
                                      );
                                    }
                                  }}
                                  className="rounded form-control-file chooseFile"
                                />
                                {formik.touched.hippaDocumentURL &&
                                  formik.errors.hippaDocumentURL && (
                                    <div className="fv-plugins-message-container">
                                      <div className="fv-help-block">
                                        {formik.errors.hippaDocumentURL}
                                      </div>
                                    </div>
                                  )}
                              </>
                            )}
                          </div>
                          {formik.touched.file && formik.errors.file && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.file}
                              </div>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>

                <div className="w-100 d-flex align-items-end justify-content-end mt-7">
                  <div>
                    <Button
                      type="submit"
                      size="lg"
                      variant="primary"
                      style={{ padding: '7px 45px' }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Container>
            </form>
          )}
        </div>
      </Card>
    </Modal>,
    modalsRoot
  );
};

export default AddEditReliasDocs;

const fileInputWithDataStyle = {
  backgroundColor: '#10355812',
  border: '1px solid #919eab',
  borderRadius: '5px',
  padding: '10px 15px',
  justifyContent: 'space-between',
};
