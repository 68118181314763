import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  documentFormData: {
    providerDocuments: [],
    providerReferences: [],
    providerSkills: [],
    companyProviders: [],
    onBoardDocs: [],
    clinicianNotes: ""
  },
};

const clinician_documents_form_slice = createSlice({
  name: 'clinician_documents_form',
  initialState,
  reducers: {
    setClinicianDocuments(state, action) {
      state.documentFormData = {
        ...state.documentFormData,
        providerDocuments: action.payload.providerDocuments || [],
      };
    },
    setProviderReferences(state, action) {
      const newReferences = action.payload.providerReferences;

      const referenceMap = new Map(
        state.documentFormData?.providerReferences?.map((ref: any) => [
          ref.id,
          ref,
        ])
      );
      newReferences?.forEach((ref: any) => {
        referenceMap?.set(ref.id, ref);
      });
      state.documentFormData.providerReferences = Array.from(
        referenceMap.values()
      );
    },
    setProviderSkills(state, action) {
      const newSkills = action.payload.providerSkills;

      const skillMap = new Map(
        state.documentFormData?.providerSkills?.map((skill: any) => [
          skill?.id,
          skill,
        ])
      );

      newSkills?.forEach((skill: any) => {
        skillMap?.set(skill.id, skill);
      });

      state.documentFormData.providerSkills = Array.from(skillMap.values());
    },
    setCompanyProviders(state, action) {
      state.documentFormData.companyProviders = action.payload;
    },
    setOnBoardDocs(state, action) {
      state.documentFormData.onBoardDocs = action.payload;
    },
    setClinicianNotesForViewScreen(state, action){
      state.documentFormData.clinicianNotes = action.payload;
    },
    resetAllDocumentsValues(state) {
      return initialState;
    },
  },
});

export const {
  setClinicianDocuments,
  setProviderReferences,
  setProviderSkills,
  setCompanyProviders,
  setOnBoardDocs,
  resetAllDocumentsValues,
  setClinicianNotesForViewScreen
} = clinician_documents_form_slice.actions;

export default clinician_documents_form_slice.reducer;
