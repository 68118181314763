import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MuiTable from '../../../../../components/table/MuiTable';
import {
  convertTimesToUTC,
  parseDateWithoutTimezone,
} from '../../../../../helpers/commonFunctions';
import { snackActions } from '../../../../../helpers/SnackUtilsConfigurator';
import {
  nextStep,
  prevStep,
  resetAssignmentFormData,
  resetStep,
  setAssignmentFormData,
} from '../../../../../store/slice/form/assignment_form';
import { KTIcon } from '../../../../helpers';
import {
  UpdateAssignment,
  addAssignment,
  getCompanyDetailsById,
  getProgram,
} from '../../api';
import './stepper-style.css';

interface ShiftDetails {
  Id: any;
  assignmentDate: any;
  startTime: any;
  endTime: any;
  invitedUserIds: any[];
  shiftvalue: any;
}

export const Step_3 = () => {
  const [dateWithDayNames, setDateWithDayNames] = useState<
    { Id: number; date: string; day: string }[]
  >([]);
  const [idCounter, setIdCounter] = useState(1);
  const [selectedtabledates, setSelectedTableDates] = useState<Date[]>([]);
  const [programname, setProgramName] = useState<any>(String);
  const [skillname, setSKillName] = useState<any>(String);
  const [checkboxStates, setCheckboxStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [shiftDetails, setShiftDetails] = useState<ShiftDetails[]>([]);
  const [selectedprovidersid, setSelectedProvidersId] = useState<any[]>([]);
  const dispatch = useDispatch();
  const formDatas = useSelector((state: any) => state.assignment.formData);
  const shiftDetailsArr = formDatas?.assignmentSchedules;
  const selectedproviders = formDatas?.InvitedUserIds;
  const SelecteddateforInvitedProvider = formDatas?.selectedTabelDates;
  const previousshiftdetail = formDatas?.shiftdetail;

  const navigate = useNavigate();

  const [isCreatingUpdatingAssignment, setIsCreatingUpdatingAssignment] =
    useState(false);

  const { mutateAsync: addAssignmentData, isLoading: isCreatingAssignment } =
    useMutation('add-golf', addAssignment);

  const { data: programData } = useQuery('program-list', getProgram);

  const { data: skillData, mutateAsync: getSkillDataAPI } = useMutation(
    'comapnydetail',
    getCompanyDetailsById
  );

  useEffect(() => {
    if (formDatas?.companyId) {
      getSkillDataAPI({ id: formDatas?.companyId });
    }
  }, [formDatas?.companyId]);

  const CreateAssignment = async () => {
    setIsCreatingUpdatingAssignment(true);
    let newAssignmentSchedules = [];
    if (formDatas?.Id) {
      newAssignmentSchedules =
        formDatas?.assignmentSchedules[0]?.dateforshift?.map((date: any) => {
          const matchingDate = shiftDetails?.find((tableDate: any) => {
            const tableDateObj = new Date(
              tableDate?.assignmentDate
                ? tableDate?.assignmentDate
                : tableDate?.AssignmentDate
            );
            return (
              tableDateObj.getFullYear() === date.getFullYear() &&
              tableDateObj.getMonth() === date.getMonth() &&
              tableDateObj.getDate() === date.getDate()
            );
          });

          if (matchingDate) {
            const assignmentDate = parseDateWithoutTimezone(
              matchingDate.assignmentDate
            );

            const convertedTimes: any = convertTimesToUTC(
              date, // Your default assignment date logic
              formDatas?.assignmentSchedules[0]?.startTime, // Provide default startTime
              formDatas?.assignmentSchedules[0]?.endTime, // Provide default endTime
              Intl.DateTimeFormat().resolvedOptions().timeZone // Specify your timezone here, or use a variable if it varies
            );
            return {
              Id: matchingDate?.Id,
              assignmentDate,
              startTime: convertedTimes?.startTime,
              endTime: convertedTimes?.endTime,
              invitedUserIds: matchingDate?.invitedUserIds,
            };
          } else {
            const assignmentDate = parseDateWithoutTimezone(date);
            const defaultStartTime = formDatas?.assignmentSchedules
              .map((time: any) => time.startTime)
              .flat()
              .join(', ');
            const defaultEndTime = formDatas?.assignmentSchedules
              .map((time: any) => time.endTime)
              .flat()
              .join(', ');
            const convertedTimes: any = convertTimesToUTC(
              date, // Your default assignment date logic
              defaultStartTime, // Provide default startTime
              defaultEndTime, // Provide default endTime
              Intl.DateTimeFormat().resolvedOptions().timeZone
            );

            return {
              Id: 0,
              assignmentDate,
              startTime: convertedTimes?.startTime,
              endTime: convertedTimes?.endTime,
              invitedUserIds: [],
            };
          }
        });
    } else {
      newAssignmentSchedules =
        formDatas?.assignmentSchedules[0]?.dateforshift?.map((date: any) => {
          const matchingDate = shiftDetails.find((tableDate: any) => {
            const tableDateObj = new Date(
              tableDate?.assignmentDate
                ? tableDate?.assignmentDate
                : tableDate?.AssignmentDate
            );
            return (
              tableDateObj.getFullYear() === date.getFullYear() &&
              tableDateObj.getMonth() === date.getMonth() &&
              tableDateObj.getDate() === date.getDate()
            );
          });

          if (matchingDate) {
            // If matchingDate exists, use its values
            const convertedTimes = convertTimesToUTC(
              matchingDate.assignmentDate,
              matchingDate.startTime,
              matchingDate.endTime,
              Intl.DateTimeFormat().resolvedOptions().timeZone
            );
            return {
              assignmentDate: parseDateWithoutTimezone(
                convertedTimes.assignmentDate
              ),
              startTime: convertedTimes.startTime,
              endTime: convertedTimes.endTime,
              invitedUserIds: matchingDate.invitedUserIds,
            };
          } else {
            const assignmentDate = parseDateWithoutTimezone(date);
            const defaultStartTime = formDatas.assignmentSchedules
              .map((time: any) => time.startTime)
              .flat()
              .join(', ');
            const defaultEndTime = formDatas.assignmentSchedules
              .map((time: any) => time.endTime)
              .flat()
              .join(', ');
            // If matchingDate doesn't exist, use defaults
            const convertedTimes = convertTimesToUTC(
              date, // Your default assignment date logic
              defaultStartTime, // Provide default startTime
              defaultEndTime, // Provide default endTime
              Intl.DateTimeFormat().resolvedOptions().timeZone
            );
            return {
              assignmentDate: parseDateWithoutTimezone(date),
              startTime: convertedTimes.startTime,
              endTime: convertedTimes.endTime,
              invitedUserIds: [],
            };
          }
        });
    }

    let address1,
      address2,
      cityIdValue,
      stateIdValue,
      zipCodeValue,
      nursingHomeId;
    if (formDatas?.selectLocationType == '2') {
      if (formDatas?.selectedPatientAddress) {
        address1 = formDatas?.selectedPatientAddress?.AddressLine1 ? formDatas?.selectedPatientAddress?.AddressLine1?.trim().replace(/\s+/g, ' ') : '';
        address2 = formDatas?.selectedPatientAddress?.AddressLine2;
        cityIdValue = formDatas?.selectedPatientAddress?.CityId;
        stateIdValue = formDatas?.selectedPatientAddress?.StateId;
        zipCodeValue = formDatas?.selectedPatientAddress?.ZipCode;
        nursingHomeId = formDatas?.selectedPatientAddress?.Id;
      }
    } else {
      address1 = formDatas?.addressLine1 ? formDatas?.addressLine1?.trim().replace(/\s+/g, ' ') : '';
      address2 = '';
      cityIdValue = parseInt(formDatas?.cityId);
      stateIdValue = parseInt(formDatas?.stateId);
      zipCodeValue = formDatas?.zipCode;
    }

    const assignmentSchedulesWithoutAssignmentDate =
      newAssignmentSchedules?.map(({ assignmentDate, ...rest }: any) => rest);

    let bodyParams = {
      Id: formDatas?.Id,
      assignmentCode: formDatas?.assignmentCode ? formDatas?.assignmentCode?.trim().replace(/\s+/g, ' ') : '',
      assignmentName: formDatas?.assignmentName ? formDatas?.assignmentName?.trim().replace(/\s+/g, ' ') : '',
      companyId: parseInt(formDatas?.companyId),
      CompanySkillIds: [parseInt(formDatas?.companySkillIds)],
      instruction: formDatas?.instruction ? formDatas?.instruction?.trim().replace(/\s+/g, ' ') : '',
      isRequiredProviderDocumentUpload: true,
      isRequiredPatientSignature: false,
      companyDocumentIds: formDatas?.companyDocumentIds ?? [],
      assignmentDocuments: formDatas?.assignmentDocuments ?? [],
      assignmentSchedules: assignmentSchedulesWithoutAssignmentDate,
      ratePerHour: formDatas?.ratePerHour,
      weekOffRate: formDatas?.weekendrate,
      requiredPersonCount: formDatas?.requiredPersonCount,
      nursingHomeId: nursingHomeId,
      patientDetails: {
        id: formDatas?.patientDetails?.id,
        patientCode: formDatas?.patientDetails?.patientCode ?  formDatas?.patientDetails?.patientCode?.trim().replace(/\s+/g, ' ') : '',
        firstName: formDatas?.patientDetails?.firstName ? formDatas?.patientDetails?.firstName?.trim().replace(/\s+/g, ' ') : '',
        lastName: formDatas?.patientDetails?.lastName ? formDatas?.patientDetails?.lastName?.trim().replace(/\s+/g, ' ') : '',
        contactNumber: String(formDatas?.patientDetails?.contactNumber),
        countryCode: String(formDatas?.patientDetails?.countryCode),
        contactPersonFirstName:
          formDatas?.patientDetails?.contactPersonFirstName ? formDatas?.patientDetails?.contactPersonFirstName?.trim().replace(/\s+/g, ' ') : '',
        contactPersonLastName: formDatas?.patientDetails?.contactPersonLastName ? formDatas?.patientDetails?.contactPersonLastName?.trim().replace(/\s+/g, ' ') : '',
        contactPersonContactNumber: String(
          formDatas?.patientDetails?.contactPersonContactNumber
        ),
        patientOtherInformationIds: formDatas?.patientOtherInformationIds,
        note: formDatas?.patientDetails?.note? formDatas?.patientDetails?.note?.trim().replace(/\s+/g, ' ') : '',
      },
      locationTypeId: formDatas?.selectLocationType == '2' ? 2 : 1,
      addressLine1: address1,
      addressLine2: address2,
      cityId: cityIdValue,
      stateId: stateIdValue,
      zipCode: zipCodeValue,
      programId: formDatas?.programId ?? null,
      shiftId: parseInt(
        formDatas?.assignmentSchedules.map(
          (shiftvalue: any) => shiftvalue.selectedShiftValue
        )
      ),
      isCustomShiftTime:
        formDatas?.shiftTimeOption == 'Option 1' ? false : true,
    };

    try {
      setIsCreatingUpdatingAssignment(true);
      let response;
      if (formDatas?.Id) {
        response = await UpdateAssignment(bodyParams);
      } else {
        response = await addAssignment(bodyParams);
      }

      if (response?.IsSuccess) {
        snackActions.success(response.Message);
        navigate('/assignment');
        dispatch(resetAssignmentFormData());
        dispatch(resetStep());
      } else {
        snackActions.error(response?.Message);
      }
    } catch (error) {
      snackActions.error('An unexpected error occurred.');
    } finally {
      setIsCreatingUpdatingAssignment(false);
    }
  };

  useEffect(() => {
    if (previousshiftdetail) {
      setShiftDetails(previousshiftdetail);
    }
  }, [previousshiftdetail]);

  useEffect(() => {
    if (selectedproviders) {
      setSelectedProvidersId(
        selectedproviders.map((provider: any) => provider?.Id)
      );
    }
  }, [selectedproviders]);

  useEffect(() => {
    if (
      selectedprovidersid &&
      selectedprovidersid.length > 0 &&
      SelecteddateforInvitedProvider
    ) {
      const newShiftDetailsArray: ShiftDetails[] = [];

      SelecteddateforInvitedProvider.forEach((date: any) => {
        const existingShiftDetailIndex = shiftDetails.findIndex(
          shift =>
            new Date(shift.assignmentDate).toDateString() ===
            new Date(date).toDateString()
        );

        if (existingShiftDetailIndex !== -1) {
          const updatedShiftDetail = {
            ...shiftDetails[existingShiftDetailIndex],
          };
          updatedShiftDetail.invitedUserIds = Array.from(
            new Set([
              ...updatedShiftDetail.invitedUserIds,
              ...selectedprovidersid,
            ])
          );

          newShiftDetailsArray.push(updatedShiftDetail);
        } else {
          const newShiftDetails: ShiftDetails = {
            Id: 0,
            assignmentDate: date,
            startTime: formDatas.assignmentSchedules[0]?.startTime,
            endTime: formDatas.assignmentSchedules[0]?.endTime,
            invitedUserIds: selectedprovidersid,
            shiftvalue: formDatas.assignmentSchedules.map(
              (time: any) => time.selectedShiftValue
            ),
          };
          newShiftDetailsArray.push(newShiftDetails);
        }
      });
      setShiftDetails(prevShiftDetails => {
        const updatedShiftDetails = [...prevShiftDetails];

        newShiftDetailsArray.forEach(newShiftDetail => {
          const index = updatedShiftDetails.findIndex(
            shift =>
              new Date(shift.assignmentDate).toDateString() ===
              new Date(newShiftDetail.assignmentDate).toDateString()
          );
          if (index !== -1) {
            updatedShiftDetails[index] = newShiftDetail;
          } else {
            updatedShiftDetails.push(newShiftDetail);
          }
        });

        return updatedShiftDetails;
      });
    }
  }, [formDatas, selectedprovidersid]);

  const handleTableCheckboxChange = (id: { date: string }) => {
    const [day, month, year] = id.date.split('/').map(Number);
    const date = new Date(year, month - 1, day);

    setSelectedTableDates((prevDates: Date[] = []) => {
      for (let d of prevDates) {
        if (d.getTime() === date.getTime()) {
          setCheckboxStates((prevCheckboxStates: any) => ({
            ...prevCheckboxStates,
            [id.date]: false,
          }));
          setShiftDetails(prevShiftDetails =>
            prevShiftDetails
              .map(shift => {
                const shiftDate = new Date(shift?.assignmentDate);
                if (shiftDate.getTime() === date.getTime()) {
                  return {
                    ...shift,
                    invitedUserIds: shift?.invitedUserIds,
                  };
                }
                return shift;
              })
              .filter(shift => shift?.invitedUserIds?.length > 0)
          );

          return prevDates.filter(d => d.getTime() !== date.getTime());
        }
      }
      setCheckboxStates((prevCheckboxStates: any) => ({
        ...prevCheckboxStates,
        [id.date]: true,
      }));

      return [...prevDates, date];
    });
  };

  useEffect(() => {
    if (formDatas?.Id) {
      if (formDatas?.clinicianshiftdetails.length > 0) {
        const transformedShiftDetails = formDatas?.clinicianshiftdetails?.map(
          (detail: any) => {
            return {
              Id: detail?.Id,
              assignmentDate: detail.EndTime,
              startTime: detail.StartTime,
              endTime: detail.EndTime,
              invitedUserIds: detail.InvitedUserIds,
              shiftValue: detail.Id,
            };
          }
        );

        setShiftDetails(transformedShiftDetails);
      } else {
        setShiftDetails(formDatas?.shiftdetail);
      }

      let fethDateArr = shiftDetailsArr[0]?.dateforshift ?? [];
      const formattedDates = fethDateArr?.map((date: any, index: any) => {
        const formattedDate = `${date.getDate()}/${date.getMonth() + 1
        }/${date.getFullYear()}`;
        const dayOfWeek = [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ][date.getDay()];
        const id = idCounter + index;
        return { Id: id, date: formattedDate, day: dayOfWeek };
      });
      setDateWithDayNames(formattedDates);

      const checkboxStates = formDatas?.clinicianshiftdetails?.reduce(
        (accumulator: any, date: any) => {
          const formattedDate = new Date(date.AssignmentDate);
          const formattedDateString = `${formattedDate.getDate()}/${
            formattedDate.getMonth() + 1
          }/${formattedDate.getFullYear()}`;
          return {
            ...accumulator,
            [formattedDateString]: true,
          };
        },
        {}
      );
    }
  }, [formDatas?.Id]);

  useEffect(() => {
    if (shiftDetailsArr && !formDatas?.Id) {
      let fethDateArr = shiftDetailsArr[0]?.dateforshift ?? [];
      const formattedDates = fethDateArr?.map((date: any, index: any) => {
        const formattedDate = `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()}`;
        const dayOfWeek = [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ][date.getDay()];
        const id = idCounter + index;
        return { Id: id, date: formattedDate, day: dayOfWeek };
      });
      setDateWithDayNames(formattedDates);
    }
  }, [shiftDetailsArr, formDatas?.Id]);

  const viewinvited = (data: any) => {
    const [day, month, year] = data.date.split('/').map(Number);
    const date = new Date(year, month - 1, day);
    dispatch(
      setAssignmentFormData({
        selectedTabelDates: selectedtabledates,
        shiftdetail: shiftDetails,
        clinicianshiftdetails: [],
        InvitedUserIds: [],
        tablecheckbox: checkboxStates,
        isforViewInvited: true,
        viewinvitedselecteddate: date,
      })
    );
    dispatch(nextStep());
  };

  useEffect(() => {
    if (formDatas?.programId && programData) {
      const programname = programData?.Result?.find(
        (programID: any) => programID?.Id == formDatas?.programId
      )?.Name;
      setProgramName(programname);
    }
  }, [formDatas, formDatas?.programId, programData]);

  useEffect(() => {
    if (formDatas?.companySkillIds && skillData) {
      const selectedSkillId = formDatas?.companySkillIds;
      const skillname = skillData?.Result?.CompanySkills?.find(
        (skillId: any) => skillId?.Id == selectedSkillId
      );
      setSKillName(skillname?.SkillName);
      dispatch(
        setAssignmentFormData({ skillIdForProviderSearch: skillname?.SkillId })
      );
    }
  }, [formDatas?.companySkillIds, skillData]);

  const normalizeDate = (date: any) => {
    const normalizedDate = new Date(date);
    normalizedDate.setHours(0, 0, 0, 0);  // Set time to midnight (00:00:00)
    return normalizedDate;
  };

  const columns: GridColDef[] = [
    {
      field: '',
      headerName: ' ',
      flex: 0.5,
      sortable: false,
      renderCell: (data: any) => {
        const currentDate = new Date();
        const [day, month, year] = data?.row?.date?.split('/');
        const date = new Date(`${month}/${day}/${year}`);
        const isPastDate = (normalizeDate(date)?.getTime() < normalizeDate(currentDate)?.getTime())
        return !isPastDate && (<input
          className="inviteCheckbox"
          type="checkbox"
          checked={checkboxStates[data.row.date] || false}
          onChange={e => handleTableCheckboxChange(data?.row)}
        />)
      }
    },
    {
      field: 'Shift No',
      headerName: 'Shift No',
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => data?.row.Id,
    },
    {
      field: 'Date',
      headerName: 'Date',
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        const dateStr = data?.row?.date;
        const datearray = dateStr?.split('/');
        const newdate = datearray[1] + '/' + datearray[0] + '/' + datearray[2];
        return <div>{newdate}</div>;
      },
    },
    {
      field: 'Days of the Week',
      headerName: 'Days of the Week',
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => data?.row.day,
    },
    {
      field: 'Action1',
      headerName: 'Action',
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        const [day, month, year] = data.row.date.split('/').map(Number);
        const date = new Date(year, month - 1, day);
        const shiftDetail: any = shiftDetails?.find((detail: any) => {
          const detailDate = new Date(
            detail?.assignmentDate
              ? detail?.assignmentDate
              : detail?.AssignmentDate
          );
          const isEqualDate = detailDate.getDate() === date.getDate();
          return isEqualDate;
        });
        const invitedUsersCount = shiftDetail
          ? shiftDetail.invitedUserIds
            ? shiftDetail.invitedUserIds.length
            : shiftDetail.InvitedUserIds
              ? shiftDetail.InvitedUserIds.length
              : 0
          : 0;
        return (
          <div
            className="d-flex align-items-center viewInviteBtn"
            onClick={() => viewinvited(data?.row)}
          >
            <span>View Invited ({invitedUsersCount})</span>
          </div>
        );
      },
    },
  ];

  const handleInvite = () => {
    if (!selectedtabledates || selectedtabledates.length === 0) {
      snackActions.error('Please select one shift');
    } else {
      dispatch(
        setAssignmentFormData({
          selectedTabelDates: selectedtabledates,
          shiftdetail: shiftDetails,
          tablecheckbox: checkboxStates,
          isforViewInvited: false,
          InvitedUserIds: [],
        })
      );
      dispatch(nextStep());
    }
  };

  return (
    <>
      <div className="my-5">
        <h3>Invite Clinician</h3>
      </div>
      <Row>
        <Col xs={12}>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <p className="pt-2 fw-normal">
                Looking for clinician for Program:{' '}
                <span className="fw-bolder">
                  {programname ? programname : '-'}
                </span>{' '}
                & Skill:{' '}
                <span className="fw-bolder">{skillname ? skillname : '-'}</span>
              </p>
            </div>
            <Button
              className="d-flex align-items-center py-3"
              onClick={handleInvite}
            >
              <KTIcon iconName="plus" className="fs-1" />
              Invite
            </Button>
          </div>
        </Col>
      </Row>
      {isCreatingUpdatingAssignment && (
        <Box className="spinner-loader">
          <CircularProgress />
        </Box>
      )}

      <Row>
        <MuiTable
          addLabel="Add New Clinician"
          columns={columns}
          isAddEnable={false}
          data={dateWithDayNames ?? []}
          isRowClickable={true}
          hideFooterPagination={true}
        />
      </Row>

      <div className="d-flex justify-content-between mt-10">
        <Button
          size="lg"
          className="px-10"
          onClick={() => dispatch(prevStep())}
          variant="secondary"
        >
          Back
        </Button>

        <Button
          type="submit"
          size="lg"
          variant="primary"
          onClick={CreateAssignment}
        >
          {isCreatingUpdatingAssignment ? 'Publishing..' : 'Publish'}
        </Button>
      </div>
    </>
  );
};
