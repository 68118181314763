import { Box, IconButton, Popover, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import 'react-datepicker/dist/react-datepicker.css';
import { MultiSelect } from 'react-multi-select-component';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useMutation, useQuery } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import { snackActions } from '../../../../helpers/SnackUtilsConfigurator';
import { isSuperAdmin, useUserInfo } from '../../../../hooks/useUserInfo';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HistoryIcon from '@mui/icons-material/History';
import {
  addProviderThrive,
  editClinician,
  getCities,
  getCompanies,
  getDocumentsDetails,
  getProgram,
  getSkills,
  getStates,
  getproviderbyid,
  uploadPicture,
} from '../api';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  convertDateToMMDDYYYY,
  formatSSN,
  parseDateWithoutTimezone,
} from '../../../../helpers/commonFunctions';
import AddEditClinicianDocs from './clinician-docs/AddEditClinicianDocs';
import AddEditRefDocs from './clinician-docs/AddEditRefDocs';
import './clinician-style.css';
import { APP_URLs } from '../../../../constants/appURLs';
import {
  resetAllDocumentsValues,
  setClinicianDocuments,
  setCompanyProviders,
  setProviderReferences,
  setProviderSkills,
} from '../../../../store/slice/form/document_form';
import { ReactComponent as ClinicianEdit } from '../../../assets/svg/clinicianEdit.svg';
import { ReactComponent as Visibility } from '../../../assets/svg/visibility.svg';
import { ReactComponent as User } from '../../../assets/svg/userprofile.svg';
import { ReactComponent as Edituser } from '../../../assets/svg/edituser.svg';
import AddEditVitasDoc from './clinician-docs/AddEditVitasDoc';
import AddEditReliasDocs from './clinician-docs/ReliasDocs';
import { DocumentCategoryEnum } from '../../../helpers/enum';
import AddEditOnBoardingDocs from './clinician-docs/AddEditOnBoardingDocs';
import ShowNotesHistoryInPopup from '../../../../helpers/notesHistoryPopup';
import AddEditHospiceDocs from './clinician-docs/AddEditHospiceDocs';

interface ISelectedCompanies {
  label: string;
  value: string;
}

interface UploadedFile {
  id: any;
  documentTypeId: string;
  name: string;
  document: {
    documentUrl: string;
    fileName: string;
  };
}

const AddEditProvider = ({ handleClose, isForEditProfile = false }: any) => {
  const location = useLocation();
  const { selectedRow }: any = location.state || {};
  const editData = selectedRow;
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showVitasModal, setShowVitasModal] = useState(false);
  const [showRefAddEditModal, setShowRefAddEditModal] = useState(false);
  const [profileImg, setProfileImg] = useState<string>('');
  const [isCompanyRequired, setIsCompanyRequired] = useState(false);
  const [showAddEditRealisModal, setShowAddEditRealisModal] = useState(false);
  const [isAllProviderDocuments, setIsAllProviderDocuments] = useState<any>()
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [anchorElForHistory, setAnchorElForHisory] = useState(null);
  const [reliasData, setReliasData] = useState<any[] | undefined>();
  const [reliasDocsValue, setReliasDocsValue] = useState<string>('');
  const [selectedCompanies, setSelectedCompnaies] = useState<
    ISelectedCompanies[] | []
  >([]);
  const [selectedProgram, setSelectedProgram] = useState<
    ISelectedCompanies[] | []
  >([]);

  const [selectedDocumentType, setSelectedDocumentType] = useState<string>('');
  const [getIdOnDocumentClick, setIdOnDocumentClick] = useState<
    any[] | undefined
  >();

  let imgUrl: string = editData?.ProfilePictureUrl
    ? editData?.ProfilePictureUrl
      ? editData?.ProfilePictureUrl
      : ''
    : '';

  const [imgFileName, setImgFileName] = useState<string>(
    imgUrl ? imgUrl?.split('/img/')[1] : ''
  );

  const userInfo = useUserInfo();

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    // ssnNumber: Yup.string().matches(/^\d{3}-\d{2}-\d{4}$/, "Invalid SSN number format").max(11, "SSN number must not exceed 11 characters"),
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    mobileNumber: Yup.string()
      .required('Mobile number is required')
      .min(10, 'Mobile number format is incorrect'),
    addressName: Yup.string().trim().required('Address is required'),
    city: Yup.string().required('City is required'),
    zip: Yup.string()
      .matches(/^[0-9]+$/, 'Zip code must contain only numbers')
      .min(5, 'Zip code must be at least 5 digits')
      .max(6, 'Zip code must be at most 6 digits')
      .required('Zip code is required'),

    state: Yup.string().required('State is required'),
    selectedCompanies: Yup.array()
      .min(1, 'Select at least one company')
      .required('Select at least one company'),
    selectedProgram: Yup.array()
      .min(1, 'Select at least one program')
      .required('Select at least one program'),
  });

  const {
    data: stateData,
    mutateAsync: getStateData,
    isLoading: isLoadingState,
  } = useMutation('states', getStates);

  const { mutateAsync: addProviderThrivedata, isLoading: isAdding } =
    useMutation('add-edit', addProviderThrive);

  const { mutateAsync: editClinicianAPI, isLoading: isEditing } = useMutation(
    'add-edit',
    editClinician
  );

  const {
    data: companiesData,
    isLoading: isLoadingCompanies,
    error: stateAPIError,
  } = useQuery('companies', getCompanies);

  const getDocumentDataFromSelector: any = useSelector(
    (state: any) => state.clinicianDoc.documentFormData
  );

  const getRefDocumentData: any = useSelector(
    (state: any) => state.clinicianDoc.documentFormData?.providerReferences
  );

  const getSkillDocumentData: any = useSelector(
    (state: any) => state.clinicianDoc.documentFormData?.providerSkills
  );

  const getProviderDocumentData: any = useSelector(
    (state: any) => state.clinicianDoc.documentFormData?.providerDocuments
  );

  const getCompanyProviderDocumentData: any = useSelector(
    (state: any) => state.clinicianDoc.documentFormData?.companyProviders
  );

  const getOnBoardDocData: any = useSelector(
    (state: any) => state.clinicianDoc.documentFormData?.onBoardDocs
  );

  const {
    data: cityData,
    mutateAsync: getCityData,
    isLoading: isLoadingCity,
  } = useMutation('cities', getCities);

  const {
    data: providerData,
    mutateAsync: getproviderId,
    isLoading: isClinicianDetailsLoading,
  } = useMutation('add-golf', getproviderbyid);

  const { data: programData, isLoading: isProgramLoading } = useQuery(
    'program-list',
    getProgram
  );

  const { mutateAsync: pictureUpload, isLoading: isLoadingSkillFile } =
    useMutation('upload-image', uploadPicture);

  const formik: any = useFormik({
    initialValues: {
      firstName: editData?.FirstName ?? '',
      lastName: editData?.LastName ?? '',
      ssnNumber: editData?.SsnNumber ?? '',
      clinicianNotes: editData?.clinicianNotes ?? '',
      selectedProgram: [],
      email: editData?.Email ?? '',
      mobileNumber: editData
        ? `${editData?.CountryCode}${editData?.MobileNumber}`
        : '',
      countryCode: editData?.CountryCode ?? '',
      addressName: editData?.Address
        ? editData?.Address?.replace(/^\s+|\s+$/g, '')
        : '',
      city: editData?.CityId ?? '',
      state: editData?.StateId ?? '',
      zip: editData?.ZipCode ?? '',
      selectedCompanies: editData?.CompanyProviders ?? [],
      skillName: '',
      dateforshift: '',
      resume:
        editData?.Documents?.find((type: any) => type.DocumentTypeId === 2)
          ?.FileName ?? null,
      tbTest:
        editData?.Documents?.find((type: any) => type.DocumentTypeId === 4)
          ?.FileName ?? null,
      autoInsuranceCard:
        editData?.Documents?.find((type: any) => type.DocumentTypeId === 5)
          ?.FileName ?? null,
      cprCard:
        editData?.Documents?.find((type: any) => type.DocumentTypeId === 3)
          ?.FileName ?? null,
      otherfile:
        editData?.Documents?.find((type: any) => type.DocumentTypeId === 1)
          ?.FileName ?? null,
    },
    validationSchema,
    onSubmit: async values => {
      const ids =
        selectedCompanies != null && selectedCompanies.length != 0
          ? selectedCompanies?.map((model: ISelectedCompanies) => model.value)
          : editData?.CompanyProviders?.map((model: any) => model.value);
      const vitasCompanyData = companiesData?.Result?.find(
        (item: any) => item?.IsRequiredNdadocument
      );

      const { documentUrl, fileName } =
        getCompanyProviderDocumentData?.[0]?.document || {};
      const selectedCompanyProviders = selectedCompanies?.map((e: any) => {
        const isVitas = vitasCompanyData?.Id == e.value;
        return {
          companyId: e?.value,
          document: {
            documentUrl: isVitas ? documentUrl : '',
            fileName: isVitas ? fileName : '',
            expiryDate: '',
          },
        };
      });

      const programIds =
        selectedProgram != null && selectedProgram.length != 0
          ? selectedProgram?.map((model: any) => model.value)
          : editData?.ProgramIds?.map((model: any) => model.value);

      const countryCodeLength = values.countryCode;
      const contactNumber = values.mobileNumber?.slice(countryCodeLength);
      const reliasData = getProviderDocumentData?.find(
        (e: any) => e?.parentId === 14
      );
      let updatedProviderDocuments = getProviderDocumentData
        ?.filter((item: any) => item?.parentId !== 14)
        ?.map((item: any) => {
          // if(item?.parentId === 14){
          //   return;
          // }
          if (
            Array.isArray((item as any)?.providerDocument) &&
            (item as any).providerDocument.length > 0
          ) {
            return { ...(item as any) };
          }
          return {
            ...(item as any),
            id: 0,
          };
        });

      let reliasDocs =
        Array.isArray(reliasData?.providerDocuments) &&
        reliasData.providerDocuments.length > 0
          ? reliasData.providerDocuments.map((item: any) => {
              if (
                Array.isArray((item as any)?.providerDocument) &&
                (item as any).providerDocument.length > 0
              ) {
                return { ...(item as any) };
              }
              return {
                ...(item as any),
                id: 0,
              };
            })
          : [];

      updatedProviderDocuments = [...updatedProviderDocuments, ...reliasDocs];

      let updatedSkillDocumentData = getSkillDocumentData?.map((item: any) => {
        if ((item as any)?.skillDocument) {
          return { ...(item as any) };
        }
        return {
          ...(item as any),
          id: 0,
        };
      });

      let updatedRefDocumentData = getRefDocumentData?.map((item: any) => ({
        ...item,
        id: 0,
      }));
      let bodyParams = {
        id: editData ? editData?.Id : undefined,
        firstName: values?.firstName ? values?.firstName?.trim().replace(/\s+/g, ' ') : '' ,
        lastName: values?.lastName ? values?.lastName?.trim().replace(/\s+/g, ' ') : '',
        mobileNumber: contactNumber ?? '',
        emailId: values?.email ? values?.email?.trim().replace(/\s+/g, ' ') : '',
        address: values?.addressName ? values?.addressName.trim().replace(/\s+/g, ' ') : '',
        cityId: values?.city ?? '',
        zipCode: values?.zip ?? '',
        stateId: values?.state ?? '',
        countryCode: 1,
        note: values?.clinicianNotes ? values?.clinicianNotes?.trim().replace(/\s+/g, ' ') : '',
        countryId: 1,
        callingFrom: 'Web',
        profilePictureUrl: profileImg ? profileImg : '',
        providerSkills: updatedSkillDocumentData,
        providerDocuments: updatedProviderDocuments,
        providerReferences: editData?.Id
          ? getRefDocumentData
          : updatedRefDocumentData,
        companyProviders: selectedCompanyProviders,
        programIds: programIds,
        createdBy: userInfo?.Id,
        providerOnboardDocuments: uploadedFiles,
        ssnNumber: values?.ssnNumber ?? 'abcd',
      };
      let response = editData?.Id
        ? await editClinicianAPI(bodyParams)
        : await addProviderThrivedata(bodyParams);
      if (response?.IsSuccess) {
        snackActions.success(response.Message);
        formik.resetForm();
        setImgFileName('');
        setProfileImg('');
        setTimeout(() => {
          navigate('/clinician');
        }, 500);
        handleClose(true);
        dispatch(resetAllDocumentsValues);
      } else {
        snackActions.error(response?.Message);
      }
    },
  });

  const { data: skilldata, isLoading: isLoadingSkill } = useQuery(
    'skills',
    getSkills
  );

  const { data: documentTypes } = useQuery(
    'documentsDetails',
    getDocumentsDetails
  );
  const [showVitasDocument, setShowVitasDocument] = useState(false);

  const detailsTypeList = documentTypes?.Result;

  useEffect(() => {
    if (selectedCompanies) {
      const isVitasDocumentShow = selectedCompanies?.some(
        (item: any) => item?.IsRequiredNdadocument
      );
      setShowVitasDocument(isVitasDocumentShow);
    }
  }, [selectedCompanies]);

  // const clinicianDocuments = detailsTypeList?.slice(0, 6);
  const clinicianDocuments = detailsTypeList?.filter(
    (item: any) => item?.DocumentCategoryId === DocumentCategoryEnum?.Document
  );

  // const backgroundCheckDocuments = detailsTypeList?.slice(6);
  const backgroundCheckDocuments = detailsTypeList?.filter(
    (item: any) =>
      item?.DocumentCategoryId === DocumentCategoryEnum?.BackgroundCheck
  );

  useEffect(() => {
    if (editData) {
      getproviderId({ id: editData.Id });
    }
  }, [editData]);

  const [isEdit, setIsEdit] = useState([]);

  const [expiryDates, setExpiryDates] = useState<Map<string, string>>(
    new Map()
  );

  const [documentUrls, setDocumentUrls] = useState<Map<any, any>>(new Map());

  useEffect(() => {
    if (detailsTypeList && getDocumentDataFromSelector) {
      const providerSkills = getDocumentDataFromSelector.providerSkills || [];
      const providerDocuments =
        getDocumentDataFromSelector.providerDocuments || [];
      const documents = providerSkills.concat(providerDocuments);

      if (Array.isArray(documents) && Array.isArray(detailsTypeList)) {
        const expiryDateMap = new Map<string, string>();
        const documentUrlMap = new Map<string, string>();
        documents.forEach(doc => {
          const key = providerData?.Result?.Id ? doc?.parentId : doc?.id;
          if (key) {
            if (doc?.document?.expiryDate) {
              expiryDateMap.set(
                key,
                convertDateToMMDDYYYY(doc.document.expiryDate)
              );
            }
            if (doc?.document?.documentUrl) {
              documentUrlMap.set(key, doc.document.documentUrl);
            }
          }
        });

        setExpiryDates(expiryDateMap);
        setDocumentUrls(documentUrlMap);
        const findDocumentId: any = detailsTypeList.filter(docId =>
          documents.some(doc => {
            const isParentIdValid = (doc?.parentId ?? doc?.id) === docId.Id;
            const hasProviderDocuments =
              doc?.parentId === 14
                ? doc?.providerDocuments?.length > 0
                : doc?.parentId === 1
                  ? doc?.document?.documentUrl !== undefined
                  : doc?.document?.documentUrl !== '';
            return isParentIdValid && hasProviderDocuments;
          })
        );
        setIsEdit(findDocumentId);
      }

      const onBoardingDocs = providerData?.Result?.Documents?.filter(
        (item: any) =>
          item?.DocumentType?.DocumentCategoryId ===
          DocumentCategoryEnum?.OnBoardDocument
      );

      const obBoarding = onBoardingDocs
        ?.map((items: any) => items?.ProviderDocuments?.[0])
        .filter((providerDocuments: any) => providerDocuments)
        .map((providerDocuments: any) => ({
          id: providerDocuments?.Id,
          documentTypeId: providerDocuments?.DocumentTypeId,
          document: {
            documentUrl: providerDocuments?.Document?.DocumentUrl,
            fileName: providerDocuments?.Document?.FileName,
          },
        }));

        const newOnboardData = obBoarding?.map((id: any) => id?.documentTypeId)
        const obBoardingData = getOnBoardDocData?.every((id: any) => newOnboardData?.includes(id?.documentTypeId))
      setUploadedFiles(obBoarding?.length ? (obBoardingData ? obBoarding : getOnBoardDocData) : getOnBoardDocData)
    }
  }, [detailsTypeList, getDocumentDataFromSelector, setIsEdit, providerData]);

  const shouldShowIcon =
    getDocumentDataFromSelector?.companyProviders?.[0]?.document?.documentUrl ??
    null;

  useEffect(() => {
    if (providerData) {
      formik.setFieldValue('firstName', providerData?.Result?.FirstName);
      formik.setFieldValue('lastName', providerData?.Result?.LastName);
      formik.setFieldValue('email', providerData?.Result?.Email);
      formik.setFieldValue(
        'clinicianNotes',
        providerData?.Result?.ProviderNotes?.[0]?.Note
      );
      formik.setFieldValue(
        'mobileNumber',
        editData?.CountryCode + editData?.MobileNumber
      );
      formik.setFieldValue('addressName', providerData?.Result?.Address);
      formik.setFieldValue('state', providerData?.Result?.StateId);
      formik.setFieldValue('city', providerData?.Result?.CityId);
      const isProfilePictureUrl = providerData?.Result?.ProfilePictureUrl;
      setProfileImg(
        isProfilePictureUrl ? `${providerData?.Result?.ProfilePictureUrl} ` : ''
      );
      formik.setFieldValue('zip', providerData?.Result?.ZipCode);

      // Existing selectedCompanies logic
      const ProviderCompanies =
        providerData?.Result?.CompanyProviders?.map((company: any) => ({
          label: company.CompanyName,
          value: company.CompanyId,
          IsRequiredNdadocument: company?.IsRequiredNdadocument,
        })) ?? [];

      const companiesList = companiesData?.Result || [];
      const companyidsFromProviderCompanies = ProviderCompanies?.map(
        (id: any) => id?.value
      );
      const companyIdsArray = Array.isArray(companyidsFromProviderCompanies)
        ? companyidsFromProviderCompanies
        : Object.values(companyidsFromProviderCompanies).map(Number);
      // const matchedCompany = companiesList?.find((company: any) => companyidsFromProviderCompanies?.includes(company.Id));
      const matchedCompany = companiesList.filter((company: any) =>
        companyIdsArray.includes(company.Id)
      );

      const selectedCompanies =
        matchedCompany?.map((company: any) => ({
          label: company.CompanyName,
          value: company.Id,
          IsRequiredNdadocument: company?.IsRequiredNdadocument,
        })) ?? [];

      formik.setFieldValue('selectedCompanies', selectedCompanies);
      setSelectedCompnaies(selectedCompanies);

      const selectedProgram = providerData?.Result?.ProgramIds?.map(
        (id: any) => {
          const program = programData.Result?.find((d: any) => d?.Id === id);
          return program ? { label: program?.Name, value: program?.Id } : null;
        }
      ).filter(Boolean);
      formik.setFieldValue('selectedProgram', selectedProgram);
      setSelectedProgram(selectedProgram);

      const filteredProviderDocuments =
        providerData?.Result?.ProviderDocuments?.filter(
          (doc: any) => doc?.DocumentType?.Id != 1
        );

      let providerDocuments = filteredProviderDocuments?.concat(
        providerData?.Result?.ProviderBackgroundChecks
      );
      const providerSkillFromAPI = providerData?.Result?.ProviderSkill;

      const payloadTypeForLicense = {
        providerSkills: [
          {
            id: providerSkillFromAPI?.Id,
            parentId: 1,
            skillId: providerSkillFromAPI?.SkillId,
            document: {
              documentUrl: providerSkillFromAPI?.Document?.DocumentUrl,
              fileName: providerSkillFromAPI?.Document?.FileName,
              expiryDate: providerSkillFromAPI?.Document?.ExpiryDate
                ? parseDateWithoutTimezone(
                    providerSkillFromAPI?.Document?.ExpiryDate
                  )
                : '',
              documentName: providerSkillFromAPI?.Document?.FileName,
            },
          },
        ],
      };
      dispatch(setProviderSkills(payloadTypeForLicense));
      const companyProviderFromAPI = selectedCompanies?.filter(
        (id: any) => id?.IsRequiredNdadocument
      );
      const vitasData = providerData?.Result?.CompanyProviders?.filter(
        (id: any) => companyProviderFromAPI[0]?.value == id?.CompanyId
      );

      const payloadTypeForCompanyProvider = [
        {
          companyId: vitasData[0]?.CompanyId ?? '',
          document: {
            documentUrl: vitasData[0]?.DocumentURL ?? '',
            fileName: vitasData[0]?.FileName ?? '',
            expiryDate: '',
          },
        },
      ];
      dispatch(setCompanyProviders(payloadTypeForCompanyProvider));
      const modifiedProviderReferences =
        providerData?.Result?.ProviderReferences?.map(
          (item: any, index: number) => ({
            ...item,
            Id: editData?.Id ? item?.Id : index,
          })
        );

      const providerReferences = modifiedProviderReferences.map(
        (item: any) => ({
          id: editData?.Id ? item.Id : 0,
          firstName: item.FirstName ?? '',
          lastName: item.LastName ?? '',
          email: item.Email ?? '',
          countryCode: '1',
          phoneNumber: item.PhoneNumber ?? '',
          designation: item.Designation ?? '',
        })
      );

      const payload = {
        providerReferences: providerReferences,
      };

      dispatch(setProviderReferences(payload));

      //end

      const defaultDataMap: any = new Map(
        getProviderDocumentData.filter((doc: any) => [
          doc.documentTypeId !== 1,
          doc,
        ])
      );
      const payloadForDocuments = {
        providerDocuments: providerDocuments?.map((item: any) => {
          const documentTypeId =
            item.DocumentType?.Id === 1
              ? 1
              : item.ProviderDocuments[0]?.DocumentTypeId;

          const providerDocumentsArray =
            item?.DocumentType?.Id === 14
              ? item.ProviderDocuments
              : item?.ProviderDocuments[0];

          return {
            id: item.ProviderDocuments[0]?.Id,
            parentId:
              item.ProviderDocuments[0]?.ParentDocumentTypeId ||
              item?.DocumentType?.Id,
            documentTypeId:
              documentTypeId ||
              item.DocumentType?.Id ||
              item.type?.Id ||
              defaultDataMap.get(item.DocumentType?.DocumentType)
                ?.documentTypeId,

            document: {
              documentUrl:
                item?.ProviderDocuments[0]?.Document?.DocumentUrl ||
                defaultDataMap.get(item?.DocumentType?.DocumentType)?.document
                  ?.documentUrl ||
                '',

              fileName:
                item?.ProviderDocuments[0]?.Document?.FileName ||
                defaultDataMap.get(item?.DocumentType?.DocumentType)?.document
                  ?.fileName ||
                '',

              expiryDate:
                item?.ProviderDocuments[0]?.Document?.ExpiryDate &&
                item.ProviderDocuments[0].Document.ExpiryDate !== 'Invalid date'
                  ? parseDateWithoutTimezone(
                      item.ProviderDocuments[0].Document.ExpiryDate
                    )
                  : '',

              documentName:
                item?.ProviderDocuments[0]?.Document?.DocumentType ||
                defaultDataMap.get(item?.DocumentType?.DocumentType)?.document
                  ?.documentName ||
                '',
            },
            providerDocument: providerDocumentsArray,
            providerDocuments: providerDocumentsArray,
          };
        }),
      };
      dispatch(setClinicianDocuments(payloadForDocuments));

      const reliadDocs = providerData?.Result?.ProviderBackgroundChecks?.filter((data: any) => data?.DocumentType?.Id == 14)
      const reliasDocsLength = reliadDocs[0]?.ProviderDocuments?.length
    
      setReliasDocsValue(reliasDocsLength)
    }
  }, [providerData, skilldata]);

  const stateValue = formik.values.state;

  useEffect(() => {
    getStateData({ countryId: 1 });
  }, []);

  useEffect(() => {
    if (stateValue) {
      getCityData({ stateId: stateValue, countryId: 1, Take: 1000  });
    }
  }, [stateValue]);

  const handleChangeImageUpload = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setImgFileName(file?.name ?? '');
    formData.append('files', file);
    formData.append('fileDriveName', 'UserProfilePictures');
    if (file) {
      if (file.size > 15 * 1024 * 1024) {
        snackActions.error('File size exceeds 15 MB limit.');
        return;
      }
      let response = await pictureUpload(formData);
      if (response?.IsSuccess) {
        setProfileImg(response?.Result[0].FileUrl);
        snackActions.success(response.Message);
      } else {
        snackActions.error(
          response?.message ?? 'Issue in image upload, try again latter!'
        );
      }
    }
  };

  const handlecompanychange = (fiedname: any, value: any) => {
    formik.setFieldValue('selectedCompanies', value);
    setSelectedCompnaies(value);
  };

  const handleProgramChange = (fiedname: any, value: any) => {
    formik.setFieldValue('selectedProgram', value);
    setSelectedProgram(value);
  };

  const handleDocumentClick = (items: any) => {
    const providerDocumentsArray = providerData?.Result?.Documents?.find(
      (item: any) => {
        const resultId = item?.ProviderDocument
          ? item?.ProviderDocument.ParentDocumentTypeId
          : item?.DocumentType?.Id;
        return resultId === items?.Id;
      }
    );

    setIdOnDocumentClick(providerDocumentsArray);
    if (items === 'references') {
      setShowRefAddEditModal(true);
    } else if (items === 'vitasDocument') {
      setShowVitasModal(true);
    } else if (items.Id == 14) {
      const data = getProviderDocumentData?.filter(
        (doc: any) => doc.parentId === items.Id
      );
      setReliasData(data);
      setShowAddEditRealisModal(true);
      setSelectedDocumentType(items);
    } else {
      setShowAddEditModal(true);
      setSelectedDocumentType(items);
    }
  };

  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
    dispatch(resetAllDocumentsValues);
    if (isSubmit) {
    }
  };

  const handleModalCloseForRef = (isSubmit: boolean) => {
    setShowRefAddEditModal(false);
    if (isSubmit) {
    }
  };

  const handleModalCloseForVitas = (isSubmit: boolean) => {
    setShowVitasModal(false);
    if (isSubmit) {
    }
  };
  const handleModalCloseForRelias = (isSubmit: boolean) => {
    setShowAddEditRealisModal(false);
    if (isSubmit) {
    }
  };

  const handleBackClick = () => {
    navigate('/clinician');
  };

  const handleVisibilityIconClick = (url: string) => {
    const baseURL = APP_URLs?.viewDocBaseURL;
    window.open(`${baseURL}/${url}`, '_blank');
  };

  const handleEditDocumentClick = (items: any) => {};

  const openHisory: boolean = Boolean(anchorElForHistory);

  const handleHistoryClick = (event: any) => {
    setAnchorElForHisory(event.currentTarget);
  };

  const handleCloseHistory = () => {
    setAnchorElForHisory(null);
  };

  const notesHistoryContent = providerData?.Result?.ProviderNotes;
  const slicedNotesValue = notesHistoryContent?.slice(1)

  const clinicianStatus = providerData?.Result?.ProfileStatusId
  let reliasDocs = getProviderDocumentData?.filter((item: any) => item?.documentTypeId == 14 || item?.parentId == 14)
  useEffect(() =>{
    if(reliasDocs[0]?.providerDocuments?.length == 5){
      setReliasDocsValue(reliasDocs[0]?.providerDocuments?.length)
    }
  }, [!providerData, !editData?.Id, reliasDocs])
  useEffect(() => {
    const isAllProviderDocuments = getProviderDocumentData?.length == 13 && reliasDocs[0]?.providerDocuments?.length == 5 && getSkillDocumentData?.length == 1 && getRefDocumentData?.length == 2 && (showVitasDocument ? (editData?.Id ? getCompanyProviderDocumentData[0]?.document?.documentUrl != "" : getCompanyProviderDocumentData?.length == 1) : true)

    setIsAllProviderDocuments(isAllProviderDocuments)
  },[getProviderDocumentData, getRefDocumentData, getCompanyProviderDocumentData, getSkillDocumentData, reliasDocs, showVitasDocument])

  return (
    <>
      <Col xs={6}>
        <div className="mb-5 backArrow" onClick={handleBackClick}>
          <ArrowBackIcon />
        </div>
      </Col>

      <Card className="">
        {/* {isLoadingSkillFile || */}
        {isClinicianDetailsLoading ||
          (isLoadingSkillFile && (
            <Box className="spinner-loader">
              <CircularProgress />
            </Box>
          ))}
        {/* ))} */}
        <div className="titleBox">
          <h2 className="mb-0">{editData ? 'Edit' : 'Add'} Clinician</h2>
          {/* end::Close */}
        </div>

        <div className="p-8">
          <div className="mb-7">
            <h2>Basic Information</h2>
          </div>
          <form onSubmit={formik.handleSubmit} noValidate className="form">
            <Row>
              <Col xl={2} lg={3} md={4} xs={12}>
                <div className="clinicianProfile">
                  <label
                    htmlFor="profile-img"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer clinicianLabel"
                  ></label>
                  <input
                    id="profile-img"
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 clinicianInput"
                    onChange={handleChangeImageUpload}
                  />
                  {profileImg ? (
                    <img
                      className="clinicianUploadedImg"
                      src={`${APP_URLs?.viewDocBaseURL}/${profileImg}`}
                      alt="Uploaded Image"
                      onClick={() => {
                        const inputElement =
                          document.getElementById('profile-img');
                        if (inputElement) {
                          inputElement.click();
                        }
                      }}
                    />
                  ) : (
                    <User
                      style={{
                        width: '140px',
                        height: '140px',
                        position: 'absolute',
                        top: '0',
                        left: '-2px',
                      }}
                      onClick={() => {
                        const inputElement =
                          document.getElementById('profile-img');
                        if (inputElement) {
                          inputElement.click();
                        }
                      }}
                    />
                  )}

                  <Edituser
                    style={{
                      width: '24px',
                      height: '24px',
                      position: 'absolute',
                      bottom: '0',
                      right: '0',
                      margin: '-1px',
                    }}
                    onClick={() => {
                      const inputElement =
                        document.getElementById('profile-img');
                      if (inputElement) {
                        inputElement.click();
                      }
                    }}
                  />
                </div>
              </Col>
              <Col xl={10} lg={9} md={8} xs={12} className="mt-4 mt-sm-0">
                <div className="d-sm-flex">
                  <div className="w-100 pe-sm-3">
                    <div className="mb-5">
                      <label className="form-label fw-bold">
                        First Name{' '}
                        <span className="fw-bolder mt-5 requriedField">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillFieldwhite"
                        placeholder="First Name"
                        aria-label="First Name"
                        {...formik.getFieldProps('firstName')}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.startsWith(' ')) {
                            e.target.value = value.trimStart();
                          }
                          formik.handleChange(e);
                        }}
                      />
                      {formik.touched.firstName && formik.errors.firstName && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.firstName}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mb-5">
                      <label className="form-label fw-bold">
                        Email{' '}
                        <span className="fw-bolder mt-5 requriedField">*</span>
                      </label>
                      <input
                        placeholder="Email"
                        aria-label="Email"
                        {...formik.getFieldProps('email')}
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillFieldwhite"
                        type="email"
                        name="email"
                        autoComplete="off"
                        disabled={editData ? true : false}
                        style={{
                          cursor: editData ? 'not-allowed' : 'initial',
                        }}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.startsWith(' ')) {
                            e.target.value = value.trimStart();
                          }
                          formik.handleChange(e);
                        }}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.email}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mb-5">
                      <label className="form-label fw-bold">SSN Number</label>
                      <input
                        placeholder="e.g. (123-45-6789)"
                        {...formik.getFieldProps('ssnNumber')}
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillFieldwhite"
                        type="text"
                        name="ssnNumber"
                        autoComplete="off"
                        maxLength={11}
                        onChange={e => {
                          const formattedValue = formatSSN(e.target.value);
                          formik.setFieldValue('ssnNumber', formattedValue);
                        }}
                      />
                      {formik.touched.ssnNumber && formik.errors.ssnNumber && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.ssnNumber}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="w-100 ps-sm-3">
                    <div className="mb-5">
                      <label className="form-label fw-bold">
                        Last Name{' '}
                        <span className="fw-bolder mt-5 requriedField">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillFieldwhite"
                        placeholder="Last Name"
                        aria-label="Last Name"
                        {...formik.getFieldProps('lastName')}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.startsWith(' ')) {
                            e.target.value = value.trimStart();
                          }
                          formik.handleChange(e);
                        }}
                      />
                      {formik.touched.lastName && formik.errors.lastName && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.lastName}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mb-5">
                      <label className="form-label fw-bold">
                        Mobile Number{' '}
                        <span className="fw-bolder mt-5 requriedField">*</span>
                      </label>
                      <PhoneInput
                        country={'us'}
                        onBlur={formik.handleBlur}
                        value={formik.values.mobileNumber}
                        onChange={(value: string, country: any) => {
                          formik.setFieldValue(
                            'countryCode',
                            country?.dialCode || ''
                          );
                          const contactNumber = value.replace(
                            country?.dialCode,
                            ''
                          );
                          formik.setFieldValue(
                            'mobileNumber',
                            `${country?.dialCode}${contactNumber}`
                          );
                        }}
                        inputStyle={{
                          width: '100%',
                        }}
                        inputClass="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillFieldwhite"
                        disabled={editData ? true : false}
                        disableDropdown={false}
                        disableCountryCode={false}
                        onlyCountries={['us']}
                      />
                      {formik.touched.mobileNumber &&
                        formik.errors.mobileNumber && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.mobileNumber}
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="mb-5">
                      <div className="d-flex justify-content-between">
                        <label className="form-label fw-bold">Notes</label>
                        {providerData?.Result?.ProviderNotes &&
                        providerData?.Result?.ProviderNotes?.length > 1 ? (
                          <label className="form-label fw-bold">
                            Notes history:{' '}
                            <IconButton
                              onClick={handleHistoryClick}
                              className="p-0"
                            >
                              <HistoryIcon
                                style={{
                                  color: '#ff3f3f',
                                  height: '22px',
                                  width: '22px',
                                }}
                              />
                            </IconButton>
                          </label>
                        ) : (
                          ''
                        )}
                      </div>
                      <textarea
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillFieldwhite"
                        placeholder="Write Something!"
                        rows="4"
                        cols="50"
                        {...formik.getFieldProps('clinicianNotes')}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value.startsWith(' ')) {
                            e.target.value = value.trimStart();
                          }
                          formik.handleChange(e);
                        }}
                      />
                    </div>
                    <ShowNotesHistoryInPopup
                      id="notes-history-popover"
                      open={openHisory}
                      anchorEl={anchorElForHistory}
                      onClose={handleCloseHistory}
                      title="Notes History"
                      notes={slicedNotesValue}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <hr />

            <label className="col-lg-4 col-form-label fw-bold fs-6">
              <span className="form-label fw-bold fontTitle">
                Address <span className="fw-bolder mt-5 requriedField">*</span>
              </span>
            </label>

            <Row>
              <Col xs={12}>
                <div className="mb-7">
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillFieldwhite"
                    placeholder="Address"
                    aria-label="Name"
                    {...formik.getFieldProps('addressName')}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Check if the first character is a space, and if it is, remove it
                      if (value.startsWith(' ')) {
                        e.target.value = value.trimStart();  // Removes the leading whitespace
                      }
                      formik.handleChange(e);  // Make sure Formik still handles the change
                    }}
                  />
                  {formik.touched.addressName && formik.errors.addressName && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.addressName}
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm={4} xs={12}>
                <div className="mb-3">
                  <select
                    disabled={isLoadingState}
                    className="form-select form-select-solid form-select-lg"
                    {...formik.getFieldProps('state')}
                    onChange={e => {
                      formik.handleChange(e);
                      const selectedState = e.target.value;
                      if (!selectedState) {
                        formik.setFieldValue('city', '');
                        cityData.data = '';
                      }
                    }}
                  >
                    <option value="">Province/State</option>
                    {stateData
                      ? stateData?.Result?.map((state: any) => (
                          <option value={state?.Id} key={state?._id}>
                            {state?.StateName} ({state?.Acronym})
                          </option>
                        ))
                      : null}
                  </select>

                  {formik.touched.state && formik.errors.state && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.state}</div>
                    </div>
                  )}
                </div>
              </Col>

              <Col sm={4} xs={12}>
                <div className="mb-3">
                  <select
                    disabled={isLoadingCity}
                    className="form-select form-select-solid form-select-lg"
                    {...formik.getFieldProps('city')}
                  >
                    <option value="">Select City</option>
                    {cityData
                      ? cityData?.Result?.map((city: any) => (
                          <option value={city?.Id} key={city?._id}>
                            {city?.CityName}
                          </option>
                        ))
                      : null}
                  </select>
                  {formik.touched.city && formik.errors.city && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.city}</div>
                    </div>
                  )}
                </div>
              </Col>

              <Col sm={4} xs={12}>
                <div className="mb-3">
                  <input
                    type="number"
                    className="form-control form-control-lg form-control-solid mb-lg-0 autofillFieldwhite"
                    placeholder="Postal code/Zip code"
                    aria-label="zip"
                    value={formik.values.zip}
                    onChange={e => {
                      if (e.target.value.length <= 5) {
                        formik.setFieldValue('zip', e.target.value);
                      }
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.zip && formik.errors.zip && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.zip}</div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            <hr />

            <Row>
              <Col sm={6} xs={12}>
                <label className="form-label fw-bold fontTitle">
                  Select Facility{' '}
                  <span className="fw-bolder mt-5 requriedField">*</span>
                </label>
                <div>
                  <MultiSelect
                    options={
                      companiesData && !isLoadingCompanies
                        ? companiesData?.Result?.map((d: any) => {
                            return {
                              label: d?.CompanyName,
                              value: d?.Id,
                              IsRequiredNdadocument: d?.IsRequiredNdadocument,
                            };
                          })
                        : []
                    }
                    value={formik.values.selectedCompanies}
                    onChange={(selected: any) =>
                      handlecompanychange('selectedCompanies', selected)
                    }
                    labelledBy="Select"
                    overrideStrings={{
                      selectSomeItems: 'Select facilities...',
                    }}
                    className="selectProgram form-control form-control-lg form-control-solid"
                  />
                </div>
                {formik.touched.selectedCompanies &&
                  formik.errors.selectedCompanies && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.selectedCompanies}
                      </div>
                    </div>
                  )}
              </Col>
              <Col sm={6} xs={12} className="mb-4">
                <label className="form-label fw-bold fontTitle">
                  Select Program{' '}
                  <span className="fw-bolder mt-5 requriedField">*</span>
                </label>
                <div>
                  <MultiSelect
                    options={
                      programData && !isProgramLoading
                        ? programData?.Result?.map((d: any) => {
                            return { label: d?.Name, value: d?.Id };
                          })
                        : []
                    }
                    value={formik?.values?.selectedProgram ?? []}
                    onChange={(selected: any) =>
                      handleProgramChange('selectedProgram', selected)
                    }
                    labelledBy="Select"
                    overrideStrings={{ selectSomeItems: 'Select Programs...' }}
                    className="selectProgram form-control form-control-lg form-control-solid"
                  />
                </div>
                {formik.touched.selectedProgram &&
                  formik.errors.selectedProgram && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.selectedProgram}
                      </div>
                    </div>
                  )}
              </Col>
            </Row>
            <hr />
            <div className="main-clinician-document">
              <label className="form-label fw-bold fontTitle">
                Clinician Documents{' '}
                <span className="fw-bolder mt-5 requriedField">*</span>
              </label>
              <div>
                <div>
                  <Row>
                    {clinicianDocuments?.map((item: any, index: any) => (
                      <Col xl={4} md={6} xs={12} key={item?.Id}>
                        <div style={documentDivStyle}>
                          <div
                            className="d-flex w-100 justify-content-between align-items-center"
                            onClick={() => handleDocumentClick(item)}
                          >
                            <div>
                              <p style={documentInnerTextStyle}>
                                {item.DocumentType}{' '}
                                <span className="fw-bolder mt-5 requriedField">
                                  *
                                </span>
                              </p>
                              {expiryDates?.get(item.Id) && (
                                <span className="backgroundExpiry">
                                  {' '}
                                  Expiry Date:{' '}
                                  {expiryDates.get(item.Id) || 'No Expiry Date'}
                                </span>
                              )}
                            </div>
                            {isEdit?.some(
                              (docId: any) => docId.Id === item.Id
                            ) ? (
                              <span
                                onClick={() => handleEditDocumentClick(item)}
                              >
                                <ClinicianEdit />
                              </span>
                            ) : (
                              <ArrowForwardIosIcon />
                            )}
                          </div>

                          {documentUrls.get(item.Id) && (
                            <span
                              className="ms-3"
                              onClick={() =>
                                handleVisibilityIconClick(
                                  documentUrls.get(item.Id)
                                )
                              }
                            >
                              <Visibility />
                            </span>
                          )}
                        </div>
                      </Col>
                    ))}
                  </Row>

                  <hr />
                  <label className="form-label fw-bold fontTitle">
                    Background Check{' '}
                    <span className="fw-bolder mt-5 requriedField">*</span>
                  </label>
                  <Row>
                    {backgroundCheckDocuments?.map((item: any, index: any) => (
                      <Col xl={4} md={6} xs={12} key={item?.Id}>
                        <div style={documentDivStyle}>
                          <div className="d-flex w-100 justify-content-between align-items-center">
                            <div
                              className="d-flex justify-content-between align-items-center w-100"
                              onClick={() => handleDocumentClick(item)}
                            >
                              <div>
                                <p style={documentInnerTextStyle}>
                                  {item.DocumentType}{' '}
                                  <span className="fw-bolder mt-5 requriedField">
                                    *
                                  </span>
                                </p>
                                {expiryDates?.get(item.Id) &&
                                  item.Id !== 14 ? (
                                    <span className="backgroundExpiry">
                                      Expiry Date:{' '}
                                      {expiryDates.get(item.Id) ||
                                        'No Expiry Date'}
                                    </span>
                                  ):(
                                    item?.Id == 14 && reliasDocsValue ? (
                                      <span>{reliasDocsValue} Documents Uploaded</span>
                                    ): null
                                  )}
                              </div>
                              <div className="clinician-edit-container">
                                {isEdit?.some(
                                  (docId: any) => docId.Id === item.Id
                                ) && (
                                  <span
                                    onClick={() =>
                                      handleEditDocumentClick(item)
                                    }
                                  >
                                    <ClinicianEdit />
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              {isEdit?.some(
                                (docId: any) => docId.Id === item.Id
                              ) ? (
                                <>
                                  <div className="other-icons">
                                    {documentUrls.get(item.Id) &&
                                      item?.Id !== 14 && (
                                        <span
                                          className="ms-3"
                                          onClick={() =>
                                            handleVisibilityIconClick(
                                              documentUrls.get(item.Id)
                                            )
                                          }
                                        >
                                          <Visibility />
                                        </span>
                                      )}
                                  </div>
                                </>
                              ) : (
                                <ArrowForwardIosIcon />
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </div>
                <hr />
                <label className="form-label fw-bold fontTitle">
                  References{' '}
                  <span className="fw-bolder mt-5 requriedField">*</span>
                </label>
                <Row>
                  <Col xl={4} md={6} xs={12}>
                    <div
                      className="mb-4"
                      style={documentDivStyle}
                      onClick={() => handleDocumentClick('references')}
                    >
                      <p style={documentInnerTextStyle}>
                        References{' '}
                        <span className="fw-bolder mt-5 requriedField">*</span>
                      </p>
                      <span>
                        {getRefDocumentData && getRefDocumentData?.length ? (
                          <ClinicianEdit />
                        ) : (
                          <ArrowForwardIosIcon />
                        )}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>

              {/* {(showVitasDocument || isCompanyRequired) && ( */}
                <>
                  <hr />
                  <label className="form-label fw-bold fontTitle">
                    VITAS Document{' '}
                    <span className="fw-bolder mt-5 requriedField">*</span>
                  </label>
                  <Row>
                    <Col xl={4} md={6} xs={12}>
                      <div style={clinicianStatus == 3 || !showVitasDocument ? disableDivStyle : documentDivStyle}>
                        <div
                          className="d-flex w-100 justify-content-between align-items-center"
                          onClick={() =>
                            clinicianStatus !== 3 && showVitasDocument && handleDocumentClick('vitasDocument')
                          }
                        >
                          <p style={documentInnerTextStyle}>VITAS Document</p>
                          <div className="d-flex align-items-center">
                            {shouldShowIcon ? (
                              <ClinicianEdit />
                            ) : (
                              <ArrowForwardIosIcon />
                            )}
                          </div>
                        </div>
                        {shouldShowIcon ? (
                          <div
                            className="cursor-pointer ms-3"
                            onClick={e => {
                              e.stopPropagation();
                              handleVisibilityIconClick(
                                getDocumentDataFromSelector?.companyProviders[0]
                                  ?.document?.documentUrl
                              );
                            }}
                          >
                            <Visibility />
                          </div>
                        ) : null}
                      </div>
                      {clinicianStatus == 3 || !showVitasDocument && (
                                  <div className="d-flex justify-content-between align-items-center mt-2">
      <span className="text-muted" style={{ fontSize: '14px' }}>
        <i className="bi bi-info-circle-fill text-info me-2"></i>
        Please select a VITAS Health Care facility to upload the VITAS document
      </span>
    </div>
                                )}
                    </Col>
                  </Row>
                </>
              {/* )} */}
              {/* {isSuperAdmin ? (
                <>
                  <hr />
                  <label className="form-label fw-bold fontTitle">
                    Hospice Onboarding Documents{' '}
                  </label>

                  <AddEditHospiceDocs
                    editData={selectedRow}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    currentPage={'add_clinician'}
                  />
                </>
              ) : null} */}
              {isSuperAdmin ? (
                <>
                  <hr />
                  <label className="form-label fw-bold fontTitle">
                    Thrive Onboarding Documents{' '}
                  </label>

                  <AddEditOnBoardingDocs
                    editData={selectedRow}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    currentPage={'add_clinician'}
                  />
                </>
              ) : null}
            </div>
            <div className="w-100 d-flex p-5 align-items-end justify-content-end mt-3">
              <Button
                className="clinicianSaveBtn"
                type="submit"
                size="lg"
                variant="primary"
                disabled={isAdding || isEditing || !isAllProviderDocuments}
              >
                {isAdding || isEditing ? 'Loading...' : 'Save'}
              </Button>
            </div>
          </form>
        </div>
      </Card>

      {showAddEditModal && (
        <AddEditClinicianDocs
          editData={selectedRow}
          show={showAddEditModal}
          onBoardData={uploadedFiles}
          handleClose={handleModalClose}
          type={selectedDocumentType}
          providerSkillFromAPI={providerData?.Result}
          getIdOnDocumentClick={getIdOnDocumentClick}
          currentPageIsView={false}
        />
      )}

      {showAddEditRealisModal && (
        <AddEditReliasDocs
          editData={selectedRow}
          show={showAddEditRealisModal}
          handleClose={handleModalCloseForRelias}
          type={selectedDocumentType}
          reliasData={reliasData}
          getIdOnDocumentClick={getIdOnDocumentClick}
        />
      )}

      {showVitasModal && (
        <AddEditVitasDoc
          editData={selectedRow}
          show={showVitasModal}
          handleClose={handleModalCloseForVitas}
          type={2}
          companyProviderDataFromAPI={providerData?.Result?.CompanyProviders}
          getIdOnDocumentClick={getIdOnDocumentClick}
        />
      )}

      {showRefAddEditModal && (
        <AddEditRefDocs
          editData={selectedRow}
          show={showRefAddEditModal}
          handleClose={handleModalCloseForRef}
          type={selectedDocumentType}
        />
      )}
    </>
  );
};

export default AddEditProvider;

const documentDivStyle = {
  border: '1px solid #919eab',
  padding: '8px 12px',
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px',
  cursor: 'pointer',
  alignItems: 'center',
  minHeight: '63px',
};

const disableDivStyle = {
  border: '1px solid #919eab',
  padding: '8px 12px',
  backgroundColor: '#e0e0e0',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px',
  cursor: 'pointer',
  alignItems: 'center',
  minHeight: '63px',
};

const documentInnerTextStyle = {
  fontSize: '16px',
  fontWeight: '600',
  margin: '0px',
};
