import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { getassignmentschedulebyid } from '../../_metronic/layout/components/api';
import {
  convertUTCTimesToLocalFuncForCheckInOut,
  formatApprovedHours,
  formatPhoneNumber,
} from '../../helpers/commonFunctions';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import './timesheet.css';
const View = () => {
  const [modifiedStartTime, setModifiedStartTime] = useState<any>();
  const [modifiedEndTime, setModifiedEndTime] = useState<any>();
  const location = useLocation();
  const navigate = useNavigate();
  const Timesheetdata = (location.state as any)?.timesheetdata || [];
  const Shiftdetailviewdata = (location.state as any)?.shiftdetailview || [];

  const {
    data: assignmentscheduledata,
    mutateAsync: getassignmentscheduleid,
    isLoading: isloadinngassignmentid,
  } = useMutation('view-timesheet', getassignmentschedulebyid);

  const resultDatas = assignmentscheduledata?.Result;

  useEffect(() => {
    if (Timesheetdata && Timesheetdata.Id) {
      getassignmentscheduleid({ id: Timesheetdata.Id });
    }
  }, [Timesheetdata, getassignmentscheduleid]);

  useEffect(() => {
    if (Shiftdetailviewdata && Shiftdetailviewdata.id) {
      getassignmentscheduleid({ id: Shiftdetailviewdata.id });
    }
  }, [Shiftdetailviewdata, getassignmentscheduleid]);

  useEffect(() => {
    if (resultDatas) {
      const modifiedStartTime = convertUTCTimesToLocalFuncForCheckInOut(
        resultDatas?.ModifyCheckIn,
        resultDatas?.ModifyCheckIn,
        resultDatas?.ModifyCheckOut,
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        'checkIn'
      );
      const modifiedEndTime = convertUTCTimesToLocalFuncForCheckInOut(
        resultDatas?.ModifyCheckIn,
        resultDatas?.ModifyCheckIn,
        resultDatas?.ModifyCheckOut,
        Intl.DateTimeFormat().resolvedOptions().timeZone,
        'clockOut'
      );
      setModifiedStartTime(modifiedStartTime);
      setModifiedEndTime(modifiedEndTime);
    }
  }, [resultDatas]);

  const handleBackClick = () => {
    navigate('/timeSheet');
  };

  return (
    <div>
      {isloadinngassignmentid && (
        <Box className="spinner-loader">
          <CircularProgress />
        </Box>
      )}
      <div style={{ padding: '10px' }}>
        <div className="backButton" onClick={handleBackClick}>
          <ArrowBackIcon />
        </div>
      </div>
      <div className="container">
        <Card className="mt-4 p-4 customeboxShadow">
          <div className="pb-3 mb-4 TimesheetHeading">
            <h2>
              {resultDatas && resultDatas?.AssignmentCode
                ? resultDatas?.AssignmentCode +
                  ' - ' +
                  resultDatas?.AssignmentName
                : 'Loading...'}
            </h2>
            <label className="fw-normal">
              {resultDatas && resultDatas
                ? resultDatas?.CompanyName
                : 'Loading...'}
            </label>
          </div>
          <Row className="p-3">
            <Col md={6} xs={12}>
              <Row>
                <Col xxl={4} xl={5} xs={6}>
                  <div>
                    <h5>Clinician</h5>
                  </div>
                  <div>
                    <h5>Scheduled Date</h5>
                  </div>
                  <div>
                    <h5>Scheduled Time</h5>
                  </div>
                  <div style={{ height: '72px' }}>
                    <h5>Patient Info</h5>
                  </div>
                </Col>
                <Col xl={5} xs={6}>
                  <div className="mb-1">
                    <label className="fw-normal">
                      {resultDatas && resultDatas?.ProviderName
                        ? resultDatas?.ProviderName
                        : 'Loading...'}
                    </label>
                  </div>
                  <div className="mb-1">
                    <label className="fw-normal">
                      {convertUTCTimesToLocalFuncForCheckInOut(
                        resultDatas?.StartTime,
                        resultDatas?.StartTime,
                        resultDatas?.EndTime,
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                        'assignmentDate'
                      )}
                    </label>
                  </div>
                  <div className="mb-1">
                    <label className="fw-normal">
                      {`${convertUTCTimesToLocalFuncForCheckInOut(
                        resultDatas?.StartTime,
                        resultDatas?.StartTime,
                        resultDatas?.EndTime,
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                        'checkIn'
                      )} - ${convertUTCTimesToLocalFuncForCheckInOut(
                        resultDatas?.StartTime,
                        resultDatas?.StartTime,
                        resultDatas?.EndTime,
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                        'clockOut'
                      )}`}
                    </label>
                  </div>
                  <div className="mb-2 d-flex flex-column">
                    <label className="fw-normal">
                      {resultDatas && resultDatas?.PatientDetails
                        ? resultDatas?.PatientDetails?.FirstName +
                          ' ' +
                          resultDatas?.PatientDetails?.LastName
                        : 'Loading...'}
                    </label>
                    <label className="fw-normal">
                      {`+${formatPhoneNumber(
                        resultDatas?.PatientDetails?.CountryCode +
                          resultDatas?.PatientDetails?.ContactNumber
                      )}`}
                    </label>
                    <label className="fw-normal">
                      {!resultDatas
                        ? 'Loading...'
                        : `${resultDatas?.AddressLine1}, ${resultDatas?.CityName}, ${resultDatas?.StateAcronym}, ${resultDatas?.ZipCode}`}
                    </label>
                  </div>
                </Col>
              </Row>
              <h3 className="mb-4 pb-1 d-inline-block actualLogTitle">
                Actual Log Hours
              </h3>
              <Row>
                <Col xxl={4} xl={5} xs={6}>
                  <div>
                    <h5 className="timesheetcircle">Clock-In</h5>
                  </div>
                  <div>
                    <h5 className="timesheetcircle">Clock-Out</h5>
                  </div>
                </Col>
                <Col xl={5} xs={6}>
                  <div>
                    <label className="fw-normal">
                      {convertUTCTimesToLocalFuncForCheckInOut(
                        resultDatas?.CheckIn,
                        resultDatas?.CheckIn,
                        resultDatas?.CheckOut,
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                        'checkIn'
                      )}
                    </label>
                  </div>
                  <div>
                    <label className="fw-normal">
                      {convertUTCTimesToLocalFuncForCheckInOut(
                        resultDatas?.CheckIn,
                        resultDatas?.CheckIn,
                        resultDatas?.CheckOut,
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                        'clockOut'
                      )}
                    </label>
                  </div>
                </Col>
              </Row>
              {modifiedStartTime &&
                modifiedStartTime != 'NaN:NaN' &&
                modifiedEndTime &&
                modifiedEndTime != 'NaN:NaN' && (
                  <span>
                    <h3 className="mb-4 pb-1 mt-3 d-inline-block actualLogTitle">
                      Modified Hrs
                    </h3>
                    <Row>
                      <Col xxl={4} xl={5} xs={6}>
                        <div>
                          <h5 className="timesheetcircle">Modified Clock-In</h5>
                        </div>
                        <div>
                          <h5 className="timesheetcircle">
                            Modified Clock Out
                          </h5>
                        </div>
                      </Col>
                      <Col xl={5} xs={6}>
                        <div>
                          <label className="fw-normal">
                            {modifiedStartTime}
                          </label>
                        </div>
                        <div>
                          <label className="fw-normal">{modifiedEndTime}</label>
                        </div>
                      </Col>
                    </Row>
                  </span>
                )}
            </Col>
            <Col md={6} xs={12} className="mt-5 mt-md-0 timesheetDetails">
              <div className="row px-3">
                <div className="col-12">
                  <Row>
                    <Col xs={12}>
                      <h3 className="mb-4 pb-1 d-inline-block actualLogTitle">
                        Timesheet Details
                      </h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Row className="mb-1">
                        <Col md={5} xs={6}>
                          <h5>Rate per Hour</h5>
                        </Col>
                        <Col md={5} xs={6}>
                          <label className="fw-normal">
                            ${resultDatas?.RatePerHour?.toFixed(2)}
                          </label>
                        </Col>
                      </Row>
                      <Row className="mb-1">
                        <Col md={5} xs={6}>
                          <h5>Approved Hours (HH:MM)</h5>
                        </Col>
                        <Col md={5} xs={6}>
                          <label className="fw-normal">
                          {resultDatas?.VerificationStatusId == 3 ? "-" : formatApprovedHours(
                              resultDatas?.AssignmentScheduleApprovedTime
                            )}
                          </label>
                        </Col>
                      </Row>
                      <Row className="mb-1">
                        <Col md={5} xs={6}>
                          <h5>Total Amount</h5>
                        </Col>
                        <Col md={5} xs={6}>
                          <label className="fw-normal">
                            ${resultDatas?.TotalAmountToBePaid?.toFixed(2)}
                          </label>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};
export default View;
